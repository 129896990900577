import React from 'react'
import { YMaps, Map, Placemark, ZoomControl, GeolocationControl } from 'react-yandex-maps'

import { Ride } from '../../lib/entities'
import { dateString, dateForTime } from '../../lib/helpers'
import api from '../../lib/api'
import config from '../../lib/config'

interface IProps {
  rideId: string
}

interface IState {
  isLoaded: boolean
  error?: Error
  ride?: Ride
}

class RideMap extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
    }
  }
  
  componentDidMount() {
    this.reloadData()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<div>Loading...</div>)
    }
    
    if (this.state.error) {
      return (<div>Error: {this.state.error.message}</div>)
    }
    
    let ride = this.state.ride
    if (!ride) {
      return (<div>Ride not found</div>)
    }
    
    let midPoint = { lat: ride.startPointLat, lng: ride.startPointLng }
    if (ride.endPointLat && ride.endPointLng) {
      midPoint = {
        lat: (midPoint.lat + ride.endPointLat) / 2,
        lng: (midPoint.lng + ride.endPointLng) / 2,
      }
    }
    
    return (
      <div className='device-map'>
        <div>
          <p>
            {dateString(dateForTime(ride.startTime))} 
            {ride.endTime ? ' - ' + dateString(dateForTime(ride.endTime)) : ''}
          </p>
        </div>
        <YMaps query={{ apikey: config.ymapsKey }}>
          <Map 
            defaultState={{ center: [midPoint.lat, midPoint.lng], zoom: 14 }} 
            width='100%' 
            height='500px' 
            modules={['geoObject.addon.editor', 'geolocation']}
          >
            <ZoomControl />
            <GeolocationControl />
            { this.renderStartPointFor(ride) }
            { this.renderEndPointFor(ride) }
          </Map>
        </YMaps>
      </div>
    )
  }
  
  renderStartPointFor(ride: Ride) {
    return (
      <Placemark 
        geometry={[ride.startPointLat, ride.startPointLng]} 
        defaultProperties={{ iconContent: 'Начало' }}
        defaultOptions={{ preset: 'islands#blueStretchyIcon' }}
      />
    )
  }
  
  renderEndPointFor(ride: Ride) {
    if (!ride || !ride.endPointLat || !ride.endPointLng) {
      return ''
    }
    
    return (
      <Placemark 
        geometry={[ride.endPointLat, ride.endPointLng]} 
        defaultProperties={{ iconContent: 'Конец' }}
        defaultOptions={{ preset: 'islands#redStretchyIcon' }}
      />
    )
  }
  
  reloadData() {
    api.rideGet(this.props.rideId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          ride: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default RideMap
