import React from 'react'
import { toast } from 'react-toastify'

import DeviceState from './device-state'
import RiderState from './rider-state'
import DeviceActions from './device-actions'

import { Device, DeviceInfo, DevicePower, RiderInfo } from '../../lib/entities'
import api from '../../lib/api'

interface IProps {
  deviceId: string
}

interface IState { 
  isLoaded: boolean
  device: Device | null
  deviceInfo: DeviceInfo | null
  devicePower: DevicePower | null
  riderInfo: RiderInfo | null
  error: Error | null
}

class DeviceControlRoot extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      device: null,
      deviceInfo: null,
      devicePower: null,
      riderInfo: null,
      error: null,
    }
  }
  
  componentDidMount() {
    this.reloadDevice()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<div>Loading...</div>)
    }
    
    if (this.state.error) {
      return (<div>Error: {this.state.error.message}</div>)
    }
    
    return (
      <div>
        <DeviceState deviceInfo={this.state.deviceInfo} devicePower={this.state.devicePower} device={this.state.device} />
        <RiderState riderInfo={this.state.riderInfo} />
        <DeviceActions deviceId={this.props.deviceId} device={this.state.device} onServiceModeChange={this.onServiceModeChange.bind(this)} />
      </div>
    )
  }
  
  reloadDevice() {
    this.setState({
      isLoaded: false,
      device: null,
      error: null,
    })
    
    api.devicesGet(this.props.deviceId).then(
      (result) => {
        this.setState({
          device: result,
        })
        this.reloadInfos()
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  reloadInfos() {
    api.devicesGetInfos(this.props.deviceId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          deviceInfo: result.deviceInfo,
          devicePower: result.devicePower,
          riderInfo: result.riderInfo,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
        })
        
        if (error.status !== 404) {
          toast.error(error.message)
        }
      }
    )
  }
  
  onServiceModeChange() {
    this.reloadDevice()
  }
}

export default DeviceControlRoot
