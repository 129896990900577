import { Link } from 'react-router-dom'

import EntitiesList from '../prototypes/list'

import { Payment } from '../../lib/entities'
import { dateString } from '../../lib/helpers'
import api from '../../lib/api'

class PaymentsList<TProps> extends EntitiesList<TProps> {
  linkPathRoot(): string {
    return '.'
  }
  
  renderHeader() {
    return (
      <tr>
        <th>ID</th>
        <th>Пользователь</th>
        <th>Самокат</th>
        <th>Дата</th>
        <th>Транзакция</th>
        <th>Сумма</th>
        <th>Статус</th>
      </tr>
    )
  }
  
  renderLine(payment: Payment) {
    return (
      <tr key={`payment_${payment.id}`}>
        <td><Link to={`${this.linkPathRoot()}/payment/${payment.id}`}>{payment.id}</Link></td>
        <td>{payment.accountId}</td>
        <td>{payment.deviceId}</td>
        <td>{dateString(new Date(payment.date))}</td>
        <td>{payment.transactionId}</td>
        <td>{payment.amount}</td>
        <td>{payment.state}</td>
      </tr>
    )
  }
  
  callApiList(page: number) {
    return api.paymentsList(page)
  }
  
  callApiCount() {
    return api.paymentsCount()
  }
}

export default PaymentsList
