import PaymentsList from '../payments/list'

import api from '../../lib/api'

interface IProps { 
  rideId: string
}

class RidePayments extends PaymentsList<IProps> {
  linkPathRoot(): string {
    return '../..'
  }
  
  callApiList(page: number) {
    return api.paymentsListForRide(this.props.rideId, page)
  }
  
  callApiCount() {
    return api.paymentsCountForRide(this.props.rideId)
  }
}

export default RidePayments
