import React from 'react'
import { Link } from 'react-router-dom'

import { Payment } from '../../lib/entities'
import { dateString } from '../../lib/helpers'
import api from '../../lib/api'

interface IProps {
  paymentId: number
}

interface IState { 
  payment: Payment | null
  isLoaded: boolean
  error: Error | null
}

class PaymentInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      payment: null,
      isLoaded: false,
      error: null,
    }
  }
  
  componentDidMount() {
    this.reloadPayment()
  }
  
  linkPathRoot(): string {
    return '..'
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    if (!this.state.payment) {
      return <div><p>Error: payment not found</p></div>
    }
    
    let payment = this.state.payment
    return (
      <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td>Пользователь</td>
              <td>{payment.accountId}</td>
            </tr>
            <tr>
              <td>Режим</td>
              <td>{payment.isSandbox ? 'тестовый' : 'боевой'}</td>
            </tr>
            <tr>
              <td>Самокат</td>
              <td><Link to={`${this.linkPathRoot()}/device/${payment.deviceId}`}>{payment.deviceId}</Link></td>
            </tr>
            <tr>
              <td>Время</td>
              <td>{dateString(new Date(payment.date))}</td>
            </tr>
            <tr>
              <td>Поездка</td>
              <td><Link to={`${this.linkPathRoot()}/ride/${payment.rideId}`}>{payment.rideId}</Link></td>
            </tr>
            <tr>
              <td>Сумма</td>
              <td>{payment.amount}</td>
            </tr>
            <tr>
              <td>Статус</td>
              <td>{payment.state}</td>
            </tr>
            <tr>
              <td>Транзакция</td>
              <td>{payment.transactionId}</td>
            </tr>
            <tr>
              <td>Банковская карта</td>
              <td>* {payment.cardLastFour}</td>
            </tr>
            <tr>
              <td>Чек</td>
              <td>
                {
                  payment.receiptId 
                    ? <a href={`https://receipts.ru/${payment.receiptId}`}>{`https://receipts.ru/${payment.receiptId}`}</a> 
                    : 'none yet'
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  
  
  reloadPayment() {
    this.setState({
      isLoaded: false,
      error: null,
    })
    
    api.paymentGet(this.props.paymentId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          payment: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default PaymentInfo
