import React from 'react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import AccountInfo from '../components/accounts/info'
import CardsList from '../components/accounts/cards'
import PaymentsList  from '../components/accounts/payments'
import AccountRides from '../components/accounts/rides'
import AccountPassword from '../components/accounts/password'
import AccountRemove from '../components/accounts/remove'

import { AccountKind, AccountKindUtil } from '../lib/entities'

interface IProps extends RouteComponentProps {
  accountId: string
  accountKind: AccountKind
}
interface IState { }

class AccountInfoScreen extends React.Component<IProps, IState> {
  render() {
    let url = this.props.match.url
    return (
      <div className='section'>
        <h3>Пользователь {this.props.accountId}</h3>
        <div className='menu'>
          <div className='menu-panel'>
            <NavLink activeClassName='is-active' to={`${url}`} exact={true}>Инфо</NavLink>
            <NavLink activeClassName='is-active' to={`${url}/rides`} exact={true}>Поездки</NavLink>
            {
              AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.superadmin)
                ? <NavLink activeClassName='is-active' to={`${url}/cards`} exact={true}>Карты</NavLink>
                : ''
            }
            <NavLink activeClassName='is-active' to={`${url}/payments`} exact={true}>Платежи</NavLink>
            <NavLink activeClassName='is-active-warning' to={`${url}/password`} exact={true}>Пароль</NavLink>
            <NavLink activeClassName='is-active-warning' to={`${url}/rm`} exact={true}>Удалить</NavLink>
          </div>
        </div>
        <div className='content'>
          <Switch>
            <Route path={`${url}/cards`}><CardsList accountId={this.props.accountId} /></Route>
            <Route path={`${url}/payments`}><PaymentsList accountId={this.props.accountId} /></Route>
            <Route path={`${url}/rides`}><AccountRides accountId={this.props.accountId} /></Route>
            <Route path={`${url}/password`}><AccountPassword accountId={this.props.accountId} /></Route>
            <Route path={`${url}/rm`}><AccountRemove accountId={this.props.accountId} /></Route>
            <Route path={`${url}`}><AccountInfo accountId={this.props.accountId} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
}  

export default withRouter(AccountInfoScreen)
