import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import EntitiesList from '../prototypes/list'
import ListHelper from './helpers/list-helper'
import PagedListHelper, { EnrichedDevice, ListFilter } from './helpers/paged-list-helper'

import DeviceBatteryCell from './device-battery-cell'
import DeviceSeenTimeCell from './device-seentime-cell'

import { Device, Branch } from '../../lib/entities'
import { DeviceMode } from '../../lib/types'
import { getTime } from '../../lib/helpers'
import api from '../../lib/api'

interface IProps {
  branch: Branch
}

class DevicesList extends EntitiesList<IProps> {
  private pagedListHelper: PagedListHelper
  private itemsPerPage: number = 50
  private activeDataFilter = ListFilter.all //TODO: think on how to use a single variable instead of this one and the one in state
  
  constructor(props: IProps) {
    super(props)
    
    this.pagedListHelper = new PagedListHelper(props.branch.id, this.itemsPerPage)
  }
  
  linkPathRoot(): string {
    return '.'
  }
  
  renderPreheader() {
    return (
      <div>
        { this.renderFilterSelector() }
      </div>
    )
  }
  
  renderHeader() {
    return (
      <tr>
        <th>Номер</th>
        <th>Режим</th>
        <th>Батарея</th>
        <th>Апдейт</th>
        <th>Комментарий</th>
      </tr>
    )
  }
  
  renderLine(enrichedDevice: EnrichedDevice) {
    return (
      <tr key={`device_${enrichedDevice.device.id}`}>
        <td><Link to={`${this.linkPathRoot()}/device/${enrichedDevice.device.id}`}>{enrichedDevice.device.id}</Link></td>
        {this.renderModeCell(enrichedDevice)}
        <DeviceBatteryCell percent={enrichedDevice.deviceInfo?.battery} />
        <DeviceSeenTimeCell time={enrichedDevice.deviceInfo?.time} />
        {this.renderCommentCell(enrichedDevice.device)}
      </tr>
    )
  }
  
  renderFilterSelector() {
    return (
      <div className='menu white'>
        <div className='menu-panel'>
          {ListHelper.availableFilters().map(filter => {
            return (
              <Link to='#'
                className={this.activeFilter() === filter ? 'is-active' : ''} 
                onClick={this.onFilterSelect.bind(this)}
                id={`filterbtn_${filter}`}
                key={`filterbtn_${filter}`}
              >
                {ListHelper.filterTitle(filter)}
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
  
  activeFilter(): ListFilter {
    return this.state.misc.activeFilter ?? ListFilter.all
  }
  
  callApiList(page: number) {
    return this.pagedListHelper.devices(page, this.activeDataFilter)
  }
  
  callApiCount() {
    return this.pagedListHelper.devicesCount(this.activeDataFilter)
  }
  
  pagesCountFor(apiCountResult: any): number {
    return Math.floor(1 + (apiCountResult - 1) / this.itemsPerPage)
  }
  
  renderModeCell(enrichedDevice: EnrichedDevice) {
    if (enrichedDevice.device.mode === DeviceMode.service) {
      return <td className='mode-service'>сервис</td>
    }
    
    if (enrichedDevice.device.mode === DeviceMode.garage) {
      return <td className='mode-garage'>в гараже</td>
    }
    
    let now = getTime()
    if (enrichedDevice.riderInfo && enrichedDevice.riderInfo.rideExpTime > now) {
      return <td className='mode-live-inuse'>в поле<br />катается</td>
    }
    
    return <td>в поле</td>
  }
  
  renderCommentCell(device: Device) {
    if (device.id === this.state.misc.commentEditingDeviceId) {
      return (
        <td>
          <textarea onChange={this.handleCommentChange.bind(this)} value={this.state.misc.commentEditingText ?? ''} />
          <br />
          <button onClick={this.handleSaveCommentClick.bind(this)}>Сохранить</button>
          <button onClick={this.handleCancelCommentClick.bind(this)}>Отменить</button>
        </td>
      )
    }
    
    return (
      <td data-device-id={device.id} onClick={this.handleCellCommentClick.bind(this)}>
        {device.comment}
      </td>
    )
  }
  
  handleCellCommentClick(e: React.MouseEvent<HTMLTableCellElement>) {
    let deviceId: string = e.currentTarget.getAttribute('data-device-id') ?? ''
    if (this.state.misc.commentEditingDeviceId === deviceId) {
      return
    }
    
    this.setState((state) => {
      let enrichedDevices: EnrichedDevice[] = state.entities as EnrichedDevice[]
      let text: string = enrichedDevices.find(enrichedDevice => enrichedDevice.device.id === deviceId)?.device.comment ?? ''
      return { 
        misc: {
          ...state.misc,
          commentEditingDeviceId: deviceId,
          commentEditingText: text,
        } 
      }
    })
  }
  
  handleCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    let text = e.currentTarget.value
    this.setState((state) => {
      return {
        misc: {
          ...state.misc,
          commentEditingText: text,
        }
      }
    })
  }
  
  handleCancelCommentClick() {
    this.setState((state) => {
      return {
        misc: {
          ...state.misc,
          commentEditingDeviceId: undefined,
          commentEditingText: undefined,
        }
      }
    })
  }
  
  handleSaveCommentClick() {
    let deviceId: string | undefined = this.state.misc.commentEditingDeviceId
    if (!deviceId) {
      return
    }
    
    let comment: string = this.state.misc.commentEditingText ?? ''
    api.devicesUpdateComment(deviceId, comment).then(
      (_result) => {
        this.setState(state => {
          let enrichedDevices: EnrichedDevice[] = state.entities as EnrichedDevice[]
          let enrichedDevice = enrichedDevices.find(device => device.device.id === deviceId)
          if (enrichedDevice) {
            enrichedDevice.device.comment = comment
          }
          
          return {
            entities: enrichedDevices,
            misc: {
              ...state.misc,
              commentEditingDeviceId: undefined,
              commentEditingText: undefined,
            }
          }
        })
      },
      (error) => {
        toast.error(`Error: ${error.message}`)
      }
    )
  }
  
  onFilterSelect(e: React.PointerEvent<HTMLAnchorElement>) {
    let activeFilter: ListFilter = +e.currentTarget.id.split('_')[1]
    this.activeDataFilter = activeFilter
    this.setState(state => { 
      return { misc: { ...state.misc, activeFilter } }
    })
    
    this.reloadData()
  }
}

export default DevicesList
