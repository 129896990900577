import React from 'react'

import { RiderInfo } from '../../lib/entities'
import { dateForTime, dateString } from '../../lib/helpers'

interface IProps {
  riderInfo: RiderInfo | null
}

interface IState { }

class RiderState extends React.Component<IProps, IState> {
  render() {
    if (!this.props.riderInfo) {
      return (
        <div>
          Не было ни одной поездки
        </div>
      )
    }
    
    return (
      <div>
        <h4>Последняя поездка</h4>
        <div className='table-container'>
          <table>
            <tbody>
              <tr>
                <td>Пользователь</td>
                <td>{this.props.riderInfo?.riderId ?? 'нет'}</td>
              </tr>
              <tr>
                <td>Самокат активен до</td>
                <td>{this.renderTime(this.props.riderInfo?.rideExpTime)}</td>
              </tr>
              <tr>
                <td>Заблокирован для остальных до</td>
                <td>{this.renderTime(this.props.riderInfo?.lockExpTime)}</td>
              </tr>
              <tr>
                <td>Блокировка вне-зоны</td>
                <td className={this.props.riderInfo?.isOutOfZone ? 'cell-error' : ''}>{this.props.riderInfo?.isOutOfZone ? 'ВКЛЮЧЕНА' : 'выключена'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  
  renderTime(time: number | undefined) {
    if (!time) {
      return 'n/a'
    }
    
    return dateString(dateForTime(time))
  }
}

export default RiderState
