import PaymentsList from '../payments/list'

import api from '../../lib/api'

interface IProps { 
  branchId: number
}

class BranchPayments extends PaymentsList<IProps> {
  linkPathRoot(): string {
    return '.'
  }
  
  callApiList(page: number) {
    return api.paymentsListForBranch(this.props.branchId, page)
  }
  
  callApiCount() {
    return api.paymentsCountForBranch(this.props.branchId)
  }
}

export default BranchPayments
