import React from 'react'

import BranchRides from '../../components/branch-info/rides'

import { Branch } from '../../lib/entities'

interface IProps {
  branch: Branch
}

class RidesScreen extends React.Component<IProps> {
  render() {
    return (
      <div className='section'>
        <div className='content'>
          <BranchRides branchId={this.props.branch.id} />
        </div>
      </div>
    )
  }
}

export default RidesScreen
