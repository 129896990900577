import config from './config'

import { AccountKind, PriceRate } from './entities'
import { DeviceMode, BranchKind } from './types'

enum Env {
    local = "local",
    staging = "staging",
    prod = "prod",
}

function server(env: Env) {
    switch (env) {
        case Env.local: return "http://localhost:8000/api/v1"
        case Env.staging: return "https://api.sct.x2.team/api/v1"
        case Env.prod:
        default: return "https://api.tenzio.ru/api/v1"
    }
}

function getServerEnv() {
    let def: Env = (config.apiServerEnv as Env) || Env.prod
    try {
        if (!document.location) return def
        
        const env = new URLSearchParams(document.location.search).get("env") as Env
        if (env && server(env)) {
            return env
        }
    } catch { }
    
    return def
}

enum RequestMode {
  anonymous, authorized, refresh
}


export class ApiScoot {
    env: Env
    baseUrl: string
    authToken?: string
    refreshToken?: string
    
    constructor() {
      this.env = getServerEnv()
      this.baseUrl = server(this.env)
      this.authToken = undefined
    }
    
    authCheck() {
        return this.post('/auth/check')
    }
    
    authRefresh() {
      return this.post('/auth/refresh', {}, RequestMode.refresh)
    }
    
    authCheckLogin(login: string) {
        return this.post('/auth/checkLogin', { login: login })
    }
    
    authLogin(login: string, pass: string, asAdmin: boolean = false) {
        return this.post('/auth/login', { login: login, password: pass, admin: asAdmin })
    }
    
    authRequestCodeForPhone(phone: string) {
        return this.post('/auth/requestPhoneCode', { phone: phone })
    }
    
    authRequestCodeForEmail(email: string) {
        return this.post('/auth/requestEmailCode', { email: email })
    }
    
    authConfirmPhoneCode(phone: string, code: string ) {
        return this.post('/auth/confirmPhoneCode', { phone: phone, code: code })
    }
    
    authConfirmEmailCode(email: string, code: string ) {
        return this.post('/auth/confirmEmailCode', { email: email, code: code })
    }
    
    authUpdatePassword(pass: string) {
        return this.post('/auth/updatePassword', { password: pass })
    }
    
    authAsAnonymous() {
        return this.post('/auth/anon')
    }
    
    
    accountsList(page: number, options?: { adminOnly?: boolean, branchId?: number }) {
      let params = {
        ...this.paginationParamsFor(page),
      }
      
      if (options?.adminOnly) {
        params = {
          ...params,
          'filter[where][kind][inq][0]': AccountKind.admin,
          'filter[where][kind][inq][1]': AccountKind.superadmin,
        }
      }
      
      if (options?.branchId) {
        params = {
          ...params,
          'filter[where][branchId]': options.branchId,
        }
      }
      
      return this.get('/accounts', params)
    }
    
    accountsCount(options?: { adminOnly?: boolean, branchId?: number }) {
      let params = { }
      
      if (options?.adminOnly) {
        params = {
          ...params,
          'where[kind][inq][0]': AccountKind.admin,
          'where[kind][inq][1]': AccountKind.superadmin,
        }
      }
      
      if (options?.branchId) {
        params = {
          ...params,
          'where[branchId]': options.branchId,
        }
      }
      
      return this.get('/accounts/count', params)
    }
    
    accountGetMy() {
      return this.get('/account/my')
    }
    
    accountGet(accountId: string) {
      return this.get(`/accounts/${accountId}`)
    }
    
    accountCreateAdmin(params: {login: string, password: string, kind: AccountKind, branchId: number}) {
      return this.post('/accounts', params)
    }
    
    accountResetPassword(accountId: string, params: { password: string, ownPassword: string }) {
      return this.post(`/accounts/${accountId}/password`, params)
    }
    
    accountRm(accountId: string) {
      return this.del(`/accounts/${accountId}`)
    }
    
    
    deviceControlGetInfo(deviceId: string) {
        return this.get(`/device/${deviceId}/info`)
    }
    
    deviceControlGetControl(deviceId: string) {
        return this.get(`/device/${deviceId}/control`)
    }
    
    deviceControlOpenLock(deviceId: string) {
        return this.post(`/device/${deviceId}/control/openLock`)
    }
    
    deviceControlEndRide(deviceId: string) {
        return this.post(`/device/${deviceId}/control/endRide`)
    }
    
    deviceToggleMode(mode: DeviceMode, deviceId: string) {
      return this.post(`/device/${deviceId}/service/mode/${mode}`)
    }
    
    deviceServiceTogglePower(isOn: boolean, deviceId: string) {
      let command: string = isOn ? 'powerOn' : 'powerOff'
      return this.post(`/device/${deviceId}/service/${command}`)
    }
    
    deviceServiceOpenLock(deviceId: string) {
      return this.post(`/device/${deviceId}/service/openLock`)
    }
    
    
    orderPrepare(deviceId: string) {
        return this.post(`/order/prepare`, { deviceId: deviceId })
    }
    
    orderRecard(deviceId: string, cardId: string) {
        return this.post('/order/recard', { deviceId: deviceId, cardId: cardId })
    }
    
    orderCancel(orderId: string) {
        return this.post(`/order/cancel`, { orderId: orderId })
    }
    
    
    paymentsGet() {
        return this.get('/payments')
    }
    
    paymentGet(paymentId: number) {
        return this.get(`/payment/${paymentId}`)
    }
    
    paymentsList(page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[order]': 'id DESC',
      }
      return this.get('/payments', params)
    }
    
    paymentsCount() {
      return this.get('/payments/count')
    }
    
    paymentsListForBranch(branchId: number, page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][branchId]': branchId,
        'filter[order]': 'id DESC',
      }
      return this.get('/payments', params)
    }
    
    paymentsCountForBranch(branchId: number) {
      return this.get('/payments/count', { 'where[branchId]': branchId })
    }
    
    paymentsGetForAccount(accountId: string, page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][accountId]': accountId,
        'filter[order]': 'id DESC',
      }
      return this.get('/payments', params)
    }
    
    paymentsCountForAccount(accountId: string) {
      return this.get('/payments/count', { 'where[accountId]': accountId })
    }
    
    paymentsListForRide(rideId: string, page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][rideId]': rideId,
        'filter[order]': 'id DESC',
      }
      return this.get('/payments', params)
    }
    
    paymentsCountForRide(rideId: string) {
      return this.get('/payments/count', { 'where[rideId]': rideId })
    }
    
    
    cardsGet() {
        return this.get('/cards')
    }
    
    cardsGetForAccount(accountId: string) {
        return this.get(`/cards/account/${accountId}`)
    }
    
    
    devicesList(page: number, options?: { branchId?: number, hideGaraged?: boolean }) {
      let params = this.paginationParamsFor(page)
      
      if (options?.branchId) {
        params = {
          ...params,
          'filter[where][branchId]': options.branchId,
        }
      }
      
      if (options?.hideGaraged) {
        params = {
          ...params,
          'filter[where][mode][neq]': DeviceMode.garage,
        }
      }
      
      return this.get('/devices', params)
    }
    
    devicesCount(options?: { branchId: number }) {
      let params = {}
      
      if (options?.branchId) {
        params = {
          ...params,
          'where[branchId]': options.branchId,
        }
      }
      
      return this.get('/devices/count', params)
    }
    
    devicesListInfo(deviceIds: string[]) {
        return this.get('/devices/info', { ids: deviceIds })
    }
    
    devicesListLocation(deviceIds: string[]) {
        return this.get('/devices/location', { ids: deviceIds })
    }
    
    devicesUpdateComment(deviceId: string, comment: string) {
        return this.patch(`/devices/${deviceId}`, { comment: comment })
    }
    
    devicesUpdateBranch(deviceId: string, branchId: number) {
      return this.patch(`/devices/${deviceId}`, { branchId: branchId })
    }
    
    devicesGet(deviceId: string) {
        return this.get(`/devices/${deviceId}`)
    }
    
    devicesGetInfos(deviceId: string) {
        return this.get(`/devices/${deviceId}/infos`)
    }
    
    devicesGetLocation(deviceId: string) {
        return this.get(`/devices/${deviceId}/location`)
    }
    
    devicesAdd(deviceId: string, branchId: number) {
      return this.post('/devices', { id: deviceId, branchId: branchId })
    }
    
    devicesRm(deviceId: string) {
        return this.del(`/devices/${deviceId}`)
    }
    
    
    zoneGet(zoneId: number) {
      return this.get(`/zones/${zoneId}`)
    }
    
    zonesList(page: number, branchId: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][branchId]': branchId,
      }
      
      return this.get('/zones', params)
    }
    
    zonesCount(branchId: number) {
      let params = {
        'where[branchId]': branchId,
      }
      return this.get('/zones/count', params)
    }
    
    zonesAdd(title: string, branchId: number) {
      return this.post('/zones', { title: title, branchId: branchId })
    }
    
    zonesRm(zoneId: number) {
      return this.del(`/zones/${zoneId}`)
    }
    
    zoneUpdate(zoneId: number, title: string | undefined, pointsJsonString: string) {
      return this.patch(`/zones/${zoneId}`, {
        title: title,
        pointsJsonString: pointsJsonString,
      })
    }
    
    
    branchGet(branchId: number) {
      return this.get(`/branches/${branchId}`)
    }
    
    branchGetByDeviceId(deviceId: string) {
      return this.get(`/branches/device/${deviceId}`)
    }
    
    branchesList(page: number) {
      let params = {
        ...this.paginationParamsFor(page),
      }
      return this.get('/branches', params)
    }
    
    branchesCount() {
      return this.get('/branches/count')
    }
    
    branchesAdd(title: string) {
      return this.post('/branches', { title: title })
    }
    
    branchesRm(branchId: number) {
      return this.del(`/branches/${branchId}`)
    }
    
    branchesUpdateTitle(branchId: number, title: string) {
      return this.patch(`/branches/${branchId}`, { title: title })
    }
    
    branchesUpdateWarningText(branchId: number, warningText: string) {
      return this.patch(`/branches/${branchId}`, { warningText })
    }
    
    branchesUpdatePurchaseText(branchId: number, purchaseText: string) {
      return this.patch(`/branches/${branchId}`, { purchaseText })
    }
    
    branchesUpdateOrderText(branchId: number, orderText: string) {
      return this.patch(`/branches/${branchId}`, { orderText })
    }
    
    branchesUpdateOrgName(branchId: number, organizationName: string) {
      return this.patch(`/branches/${branchId}`, { organizationName })
    }
    
    branchesUpdateOrgInn(branchId: number, organizationInn: string) {
      return this.patch(`/branches/${branchId}`, { organizationInn })
    }
    
    branchesUpdateKind(branchId: number, kind: BranchKind) {
      return this.patch(`/branches/${branchId}`, { kind })
    }
    
    branchConfigGet(branchId: number) {
      return this.get(`/branches/${branchId}/config`)
    }
    
    branchConfigUpdateTelegramChatId(branchId: number, telegramChatId: string) {
      return this.patch(`/branches/${branchId}/config`, { telegramChatId: telegramChatId })
    }
    
    
    rideGet(rideId: string) {
      return this.get(`/rides/${rideId}`)
    }
    
    ridesList(page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[order]': 'startTime DESC',
      }
      return this.get('/rides', params)
    }
    
    ridesCount() {
      return this.get('/rides/count')
    }
    
    ridesListForAccount(accountId: string, page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][accountId]': accountId,
        'filter[order]': 'startTime DESC',
      }
      return this.get('/rides', params)
    }
    
    ridesCountForAccount(accountId: string) {
      return this.get('/rides/count', { 'where[accountId]': accountId })
    }
    
    ridesListForDevice(deviceId: string, page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][deviceId]': deviceId,
        'filter[order]': 'startTime DESC',
      }
      return this.get('/rides', params)
    }
    
    ridesCountForDevice(deviceId: string) {
      return this.get('/rides/count', { 'where[deviceId]': deviceId })
    }
    
    ridesListForBranch(branchId: number, page: number) {
      let params = {
        ...this.paginationParamsFor(page),
        'filter[where][branchId]': branchId,
        'filter[order]': 'startTime DESC',
      }
      return this.get('/rides', params)
    }
    
    ridesCountForBranch(branchId: number) {
      return this.get('/rides/count', { 'where[branchId]': branchId })
    }
    
    pricesGet(branchId: number) {
      return this.get(`/branch/${branchId}/prices`)
    }
    
    pricesPost(branchId: number, rates: PriceRate[]) {
      return this.post(`/branch/${branchId}/prices`, { priceRates: rates })
    }

    async downloadFirmwareFile(deviceId: string): Promise<string> {
      let url = this.baseUrl + `/device/${deviceId}/firmware`
      let response = await fetch(url, { headers: this.headers(RequestMode.authorized) } as object)
      let data = await response.blob()
      let file = new Blob([data], { type: 'text/plain' })
      let fileUrl = URL.createObjectURL(file)
      return fileUrl
    }


    paginationParamsFor(page: number, itemsPerPage: number = config.itemsPerPage): object {
      return {
          'filter[limit]': itemsPerPage,
          'filter[skip]': itemsPerPage * (page - 1),
      }
    }
    
    pageCountFor(itemsCount: number, itemsPerPage: number = config.itemsPerPage): number {
      return 1 + Math.floor((itemsCount - 1) / itemsPerPage)
    }
    
    
    get(endpoint: string, params?: object, mode: RequestMode = RequestMode.authorized) {
      let url = this.baseUrl + endpoint
      if (params) {
        const keys = Object.keys(params)
        url += "?" + keys.map(k => k + "=" + (params as any)[k]).join("&")
      }
      return this.fetchURL(url, mode)
    }
    
    post(endpoint: string, json: object = {}, mode: RequestMode = RequestMode.authorized) {
      return this.jsonRequest("POST", endpoint, mode, json)
    }
    
    patch(endpoint: string, json: object = {}, mode: RequestMode = RequestMode.authorized) {
      return this.jsonRequest("PATCH", endpoint, mode, json)
    }
    
    del(endpoint: string, json: object = {}, mode: RequestMode = RequestMode.authorized) {
      return this.jsonRequest("DELETE", endpoint, mode, json)
    }
    
    jsonRequest = (method: string, endpoint: string, mode: RequestMode, json: object) => {
      return this.fetch(this.baseUrl + endpoint, {
        method,
        headers: {
          "Content-Type": "application/json",
          ...this.headers(mode)
        },
        body: JSON.stringify(json)
      })
    }

    fetchURL = (url: string, mode: RequestMode) => {
      return this.fetch(url, {
        headers: this.headers(mode),
      })
    }

    async fetch(url: string, options: object) {
        const response = await fetch(url, options)
        
        if (!response.ok) {
            let errorMessage = response.statusText
            if (isJSON(response)) {
                const json = await response.json()
                errorMessage = describeError(json)
            }
            throw new ApiError(response.status, errorMessage)
        }
        
        if (!isJSON(response)) {
            throw new Error('Malformed response')
        }
        
        return await response.json()
    }
    
    headers(mode: RequestMode): object {
      switch (mode) {
        case RequestMode.anonymous: return {}
        case RequestMode.authorized: return this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}
        case RequestMode.refresh: return this.refreshToken ? { Authorization: `Bearer ${this.refreshToken}` } : {}
      }
    }
}

function describeError(obj: any): string {
    const keys = Object.keys(obj)
    if (obj.message) {
        return obj.message
    } else if (obj.error) {
        return describeError(obj.error)
    } else if (keys.length) {
        return keys.map(k => {
            const v = obj[k]
            return k + " - " + (Array.isArray(v) ? v[0] : v)
        })[0]
    } else {
        // The worst case
        return "API Error"
    }
}
    
class ApiError extends Error {
    constructor(status: number, message: string) {
        super(message)
        this.status = status
        this.name = this.constructor.name
        
        if (typeof Error["captureStackTrace"] === "function") {
            Error["captureStackTrace"](this, this.constructor)
        } else {
            this.stack = new Error(message).stack
        }
    }
    
    status: number
}

function isJSON(response: Response) {
    const contentType = response.headers.get("content-type")
    return contentType && contentType.includes("application/json")
}

const api = new ApiScoot()

export default api
