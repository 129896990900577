import React from 'react'
import { Pagination } from '@material-ui/lab'

import api from '../../lib/api'

interface IState { 
  entities: object[]
  isLoaded: boolean
  error: Error | null
  page: number
  pagesCount: number
  misc: any
}

class EntitiesList<TProps> extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    
    this.state = {
      entities: [],
      isLoaded: false,
      error: null,
      page: 1,
      pagesCount: 1,
      misc: {},
    }
  }
  
  componentDidMount() {
    this.reloadData()
  }
  
  reloadData() {
    this.reloadEntities()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    if (this.state.entities.length < 1) {
      return (
        <div>
          { this.renderPreheader() }
          <p>Пока ничего нет</p>
        </div>
      )
    }
    
    return (
      <div>
        { this.renderPreheader() }
        <div className='paginator-container'>
          <Pagination page={this.state.page} count={this.state.pagesCount} onChange={this.handleChangePage.bind(this)} />
        </div>
        <div className='table-container'>
          <table>
            <thead>{this.renderHeader()}</thead>
            <tbody>{this.state.entities.map((entity) => this.renderLine(entity))}</tbody>
          </table>
        </div>
      </div>
    )
  }
  
  
  //MARK: Stuff to override
  
  renderPreheader() {
    return (<div />)
  }
  
  renderHeader() {
    return (<tr></tr>)
  }
  
  renderLine(_entity: object) {
    return (<tr></tr>)
  }
  
  callApiList(_page: number): Promise<any> {
    return this.placeholder()
  }
  
  callApiCount(): Promise<any> {
    return this.placeholder()
  }
  
  onPageLoaded() {
    
  }
  
  pagesCountFor(apiCountResult: any): number {
    return api.pageCountFor(apiCountResult.count ?? 0)
  }
  
  
  //MARK: Private stuff
  
  private async placeholder() {
    throw new Error('Override those methods above')
  }
  
  private reloadEntities(page: number = 1) {
    this.setState({
      isLoaded: false,
      error: null,
      page: page,
    })
    
    this.callApiList(page).then(
      (result) => {
        this.setState({
          isLoaded: true,
          entities: result,
          page: page,
        })
        
        if (page === 1) {
          this.reloadEntitiesCount()
        }
        
        this.onPageLoaded()
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  private reloadEntitiesCount() {
    this.callApiCount().then(
      (result) => {
        this.setState({
          pagesCount: this.pagesCountFor(result)
        })
      }
    )
  }
  
  private handleChangePage(_event: object, page: number) {
    this.reloadEntities(page)
  }
}

export default EntitiesList
