import React from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import UserPanel from '../../components/user-panel'
import RootMenuPanel from '../../components/root-menu-panel'
import MenuPanel from './components/menu-panel'

import BranchesScreen from './branches.screen'
import UsersScreen from './users.screen'
import UserInfoScreen from '../account-info.screen'

import { Account } from '../../lib/entities'
import fixUrl from '../../lib/utils/fix-url'

interface ModelIdMatchParams {
  id: string
}

interface IProps extends RouteComponentProps {
  account: Account
}

interface IState {}

class AdminRootScreen extends React.Component<IProps, IState> {
  render() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div>
        <div className='top-bar'>
          <RootMenuPanel accountKind={this.props.account.kind} />
          <UserPanel account={this.props.account} />
        </div>
        <div className='rides section'>
          <div className='menu'>
            <MenuPanel />
          </div>
          <div className='content'>
            <Switch>
              <Route path={`${path}/branches`}><BranchesScreen /></Route>
              <Route path={`${path}/users`}><UsersScreen account={this.props.account} /></Route>
              
              <Route path={`${path}/user/:id`} render={this.renderUserScreen.bind(this)}></Route>
              
              <Route path={`${path}/`}><Redirect to={`${url}/branches`} /></Route>
            </Switch>
          </div>
        </div>
      </div>
    )
  }
  
  renderUserScreen({match}: RouteComponentProps<ModelIdMatchParams>) {
    return <UserInfoScreen accountId={match.params.id} accountKind={this.props.account.kind} /> 
  }
}

export default withRouter(AdminRootScreen)
