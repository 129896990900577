import React from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import UserPanel from '../../components/user-panel'
import RootMenuPanel from '../../components/root-menu-panel'
import MenuPanel from './components/menu-panel'

import DevicesScreen from './devices.screen'
import MapScreen from './map.screen'
import PaymentsScreen from './payments.screen'
import PricesScreen from './prices.screen'
import UsersScreen from './users.screen'
import RidesScreen from './rides.screen'
import ConfigScreen from './config.screen'
import ZonesScreen from './zones.screen'
import DeviceInfoScreen from '../device-info.screen'
import UserInfoScreen from '../account-info.screen'
import RideInfoScreen from '../ride-info.screen'
import PaymentInfoScreen from '../payment-info.screen'
import ZoneInfoScreen from '../zone-info.screen'

import { Account, Branch } from '../../lib/entities'
import fixUrl from '../../lib/utils/fix-url'
import api from '../../lib/api'

interface ModelIdMatchParams {
  id: string
}

interface IProps extends RouteComponentProps {
  account: Account
  branchId: number
}

interface IState {
  isLoaded: boolean
  branch?: Branch
  error?: Error
}

class BranchRootScreen extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
    }
  }
  
  componentDidMount() {
    this.reloadBranch(this.props.branchId)
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div>Loading..</div>
    }
    
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>
    }
    
    if (!this.state.branch) {
      return <div>Error: no branch selected. Please reload page</div>
    }
    
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div>
        <div className='top-bar'>
          <RootMenuPanel accountKind={this.props.account.kind} branch={this.state.branch} />
          <UserPanel account={this.props.account} />
        </div>
        <div className='section'>
          <div className='menu'>
            <MenuPanel accountKind={this.props.account.kind} />
          </div>
          <Switch>
            <Route path={`${path}/devices`}><DevicesScreen branch={this.state.branch} account={this.props.account} /></Route>
            <Route path={`${path}/map`}><MapScreen branch={this.state.branch} /></Route>
            <Route path={`${path}/payments`}><PaymentsScreen branch={this.state.branch} /></Route>
            <Route path={`${path}/prices`}><PricesScreen branch={this.state.branch} /></Route>
            <Route path={`${path}/users`}><UsersScreen branch={this.state.branch} account={this.props.account} /></Route>
            <Route path={`${path}/rides`}><RidesScreen branch={this.state.branch} /></Route>
            <Route path={`${path}/config`}><ConfigScreen branch={this.state.branch} account={this.props.account} /></Route>
            <Route path={`${path}/zones`}><ZonesScreen branch={this.state.branch} /></Route>
            
            <Route path={`${path}/device/:id`} render={this.renderDeviceScreen.bind(this)}></Route>
            <Route path={`${path}/user/:id`} render={this.renderUserScreen.bind(this)}></Route>
            <Route path={`${path}/ride/:id`} render={this.renderRideScreen.bind(this)}></Route>
            <Route path={`${path}/payment/:id`} render={this.renderPaymentScreen.bind(this)}></Route>
            <Route path={`${path}/zone/:id`} render={this.renderZoneScreen.bind(this)}></Route>
            
            <Route path={`${path}/`}><Redirect to={`${url}/devices`} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
  
  renderDeviceScreen({match}: RouteComponentProps<ModelIdMatchParams>) {
    return <DeviceInfoScreen deviceId={match.params.id} accountKind={this.props.account.kind} />
  }
  
  renderUserScreen({match}: RouteComponentProps<ModelIdMatchParams>) {
    return <UserInfoScreen accountId={match.params.id} accountKind={this.props.account.kind} />
  }
  
  renderRideScreen({match}: RouteComponentProps<ModelIdMatchParams>) {
    return <RideInfoScreen rideId={match.params.id} />
  }
  
  renderPaymentScreen({match}: RouteComponentProps<ModelIdMatchParams>) {
    return <PaymentInfoScreen paymentId={+match.params.id} />
  }
  
  renderZoneScreen({match}: RouteComponentProps<ModelIdMatchParams>) {
    return <ZoneInfoScreen zoneId={+match.params.id} />
  }
  
  
  reloadBranch(branchId: number) {
    this.setState({
      isLoaded: false,
    })
    
    api.branchGet(branchId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          branch: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default withRouter(BranchRootScreen)
