import RidesList from '../rides/list'

import api from '../../lib/api'

interface IProps { 
  accountId: string
}

class DeviceRides extends RidesList<IProps> {
  callApiList(page: number) {
    return api.ridesListForAccount(this.props.accountId, page)
  }
  
  callApiCount() {
    return api.ridesCountForAccount(this.props.accountId)
  }
}

export default DeviceRides
