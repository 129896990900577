import RidesList from '../rides/list'

import api from '../../lib/api'

interface IProps { 
  deviceId: string
}

class AccountRides extends RidesList<IProps> {
  linkPathRoot(): string {
    return '../..'
  }
  
  callApiList(page: number) {
    return api.ridesListForDevice(this.props.deviceId, page)
  }
  
  callApiCount() {
    return api.ridesCountForDevice(this.props.deviceId)
  }
}

export default AccountRides
