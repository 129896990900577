import ListHelper, { ListFilter, EnrichedDevice } from './list-helper'
export * from './list-helper'

type PLHFilteredCache = {
  enrichedDevices: EnrichedDevice[],
  filter: ListFilter,
}

export default class PagedListHelper
{
  private listHelper: ListHelper
  private cache?: PLHFilteredCache
  private itemsPerPage: number
  
  constructor(branchId: number, itemsPerPage: number)
  {
    this.listHelper = new ListHelper(branchId)
    this.itemsPerPage = itemsPerPage
  }
  
  public async devicesCount(filter: ListFilter = ListFilter.all): Promise<number> {
    let devices = await this.cachedDevices(filter)
    return devices.length
  }
  
  public async devices(page: number, filter: ListFilter = ListFilter.all): Promise<EnrichedDevice[]> {
    let devices = await this.cachedDevices(filter)
    return devices.slice(this.itemsPerPage * (page - 1), this.itemsPerPage * page)
  }
  
  private async cachedDevices(filter: ListFilter): Promise<EnrichedDevice[]> {
    if (this.cache && this.cache.filter === filter) {
      return this.cache.enrichedDevices
    }
    
    let cache = await this.reloadCache(filter)
    this.cache = cache
    
    return cache.enrichedDevices
  }
  
  private async reloadCache(filter: ListFilter): Promise<PLHFilteredCache> {
    let enrichedDevices = await this.listHelper.updateData(filter)
    return { enrichedDevices, filter }
  }
}
