import React from 'react'
import { toast } from 'react-toastify'

import { Device } from '../../lib/entities'
import { DeviceMode } from '../../lib/types'
import api from '../../lib/api'

interface IProps {
  deviceId: string
  device: Device | null
  onServiceModeChange: () => void
}

interface IState { }

class DeviceActions extends React.Component<IProps, IState> {
  render() {
    return (
      <div className='device-actions'>
        <h4>Управление</h4>
        {this.renderButtons()}
      </div>
    )
  }
  
  renderButtons() {
    if (!this.props.device || this.props.device.mode === DeviceMode.live) {
      return (
        <div>
          <button value='on' onClick={this.handleModeService.bind(this)}>Включить сервис-режим</button>
          <br />
          Обычные пользователи не смогут воспользоваться самокатом пока сервис-режим включен
          <br />
          Если кто-то катается на самокате прямо сейчас - <b>самокат будет выключен</b>.
        </div>
      )
    }
    
    return (
      <div>
        <button onClick={this.handleModeLive.bind(this)}>Выключить сервис-режим</button>
        <br />
        {
          this.props.device.mode === DeviceMode.service
            ? <button onClick={this.handleModeGarage.bind(this)}>Отправить в гараж</button>
            : <button onClick={this.handleModeService.bind(this)}>Убрать из гаража</button>
        }
        <br />
        <button onClick={this.handlePowerOn.bind(this)}>Включить</button>
        <button onClick={this.handlePowerOff.bind(this)}>Выключить</button>
        <br />
        <button onClick={this.handleOpenLock.bind(this)}>Открыть замок</button>
        <br />
        Самокат в режиме <i>в гараже</i> не будет отображаться на карте и не будет слать никаких нотификаций
      </div>
    )
  }
  
  handleModeService() {
    this.toggleDeviceMode(DeviceMode.service)
  }
  
  handleModeLive() {
    this.toggleDeviceMode(DeviceMode.live)
  }
  
  handleModeGarage() {
    this.toggleDeviceMode(DeviceMode.garage)
  }
  
  handlePowerOn() {
    this.togglePower(true)
  }
  
  handlePowerOff() {
    this.togglePower(false)
  }
  
  handleOpenLock() {
    api.deviceServiceOpenLock(this.props.deviceId).then(
      (_result) => {
        toast.success('Открываем..', { autoClose: 2000 })
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  toggleDeviceMode(mode: DeviceMode) {
    api.deviceToggleMode(mode, this.props.deviceId).then(
      (_result) => {
        this.props.onServiceModeChange()
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  togglePower(isOn: boolean) {
    api.deviceServiceTogglePower(isOn, this.props.deviceId).then(
      (_result) => {
        toast.success((isOn ? 'Включаем' : 'Выключаем') + '..', { autoClose: 2000 })
        this.props.onServiceModeChange()
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default DeviceActions
