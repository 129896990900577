import React, { Fragment } from 'react'
import { NavLink, Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom'
import fixUrl from '../../../lib/utils/fix-url'
import { AccountKind, AccountKindUtil } from '../../../lib/entities'

interface ModelIdMatchParams {
  id: string
}

interface IProps extends RouteComponentProps {
  accountKind: AccountKind
}

class MenuPanel extends React.Component<IProps> {
  render() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div className='menu-panel'>
        <NavLink activeClassName='is-active' to={`${url}/devices`}>Самокаты</NavLink>
        <NavLink activeClassName='is-active' to={`${url}/map`}>Карта</NavLink>
        
        {
          AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.admin)
            ? <Fragment>
                <NavLink activeClassName='is-active' to={`${url}/prices`}>Прайс-лист</NavLink>
                <NavLink activeClassName='is-active' to={`${url}/zones`}>Зоны</NavLink>
                <NavLink activeClassName='is-active' to={`${url}/payments`}>Платежи</NavLink>
                <NavLink activeClassName='is-active' to={`${url}/users`}>Пользователи</NavLink>
                <NavLink activeClassName='is-active' to={`${url}/rides`}>Поездки</NavLink>
                <NavLink activeClassName='is-active' to={`${url}/config`}>Конфиг</NavLink>
              </Fragment> 
            : ''
        }
        
        <Switch>
          <Route path={`${path}/device/:id`} render={this.renderDeviceControl.bind(this)} />
          <Route path={`${path}/user/:id`} render={this.renderUserControl.bind(this)} />
          <Route path={`${path}/ride/:id`} render={this.renderRideControl.bind(this)} />
          <Route path={`${path}/payment/:id`} render={this.renderPaymentControl.bind(this)} />
          <Route path={`${path}/zone/:id`} render={this.renderZoneControl.bind(this)} />
        </Switch>
      </div>
    )
  }

  renderDeviceControl({match}: RouteComponentProps<ModelIdMatchParams>) {
    let url = fixUrl(this.props.match.url)
    let deviceId = match.params.id
    return <NavLink activeClassName='is-active' to={`${url}/device/${deviceId}`}>Самокат {deviceId}</NavLink>
  }

  renderUserControl({match}: RouteComponentProps<ModelIdMatchParams>) {
    let url = fixUrl(this.props.match.url)
    let userId = match.params.id
    return <NavLink activeClassName='is-active' to={`${url}/user/${userId}`}>Пользователь {userId.split('-')[0]}</NavLink>
  }

  renderRideControl({match}: RouteComponentProps<ModelIdMatchParams>) {
    let url = fixUrl(this.props.match.url)
    let rideId = match.params.id
    return <NavLink activeClassName='is-active' to={`${url}/ride/${rideId}`}>Поездка {rideId.split('-')[0]}</NavLink>
  }

  renderPaymentControl({match}: RouteComponentProps<ModelIdMatchParams>) {
    let url = fixUrl(this.props.match.url)
    let paymentId = match.params.id
    return <NavLink activeClassName='is-active' to={`${url}/payment/${paymentId}`}>Платеж {paymentId}</NavLink>
  }

  renderZoneControl({match}: RouteComponentProps<ModelIdMatchParams>) {
    let url = fixUrl(this.props.match.url)
    let zoneId = +match.params.id
    return <NavLink activeClassName='is-active' to={`${url}/zone/${zoneId}`}>Зона {zoneId}</NavLink>
  }
}

export default withRouter(MenuPanel)
