import React from 'react'
import { toast } from 'react-toastify'

import { Device, Branch, AccountKind, AccountKindUtil } from '../../lib/entities'
import api from '../../lib/api'

interface IProps {
  deviceId: string
  accountKind: AccountKind
}

interface IState { 
  device?: Device
  error?: Error
  isLoaded: boolean
  branches: Branch[]
  
  isEditingBranch: boolean
  selectedBranchId?: number
  
  isEditingComment: boolean
  commentText?: string
}

class DeviceInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      branches: [],
      isEditingBranch: false,
      isEditingComment: false,
    }
  }
  
  componentDidMount() {
    this.reloadDevice()
    this.reloadBranches()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div>Loading...</div>
    }
    
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>
    }
    
    return (
      <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td>Бренч</td>
              {this.renderBranchCell()}
            </tr>
            <tr>
              <td>Режим</td>
              <td>{this.state.device?.mode}</td>
            </tr>
            <tr>
              <td>Комментарий</td>
              {this.renderCommentCell()}
            </tr>
            {
              AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.superadmin) 
                ? (
                  <tr>
                    <td>Прошивка</td>
                    <td><button onClick={this.handleFirmwareDownload.bind(this)}>скачать</button></td>
                  </tr>
                )
                : ''
            }
          </tbody>
        </table>
      </div>
    )
  }
  
  renderBranchCell() {
    if (this.state.isEditingBranch) {
      return (
        <td>
          <select onChange={this.handleBranchChange.bind(this)} value={this.state.selectedBranchId}>
            {
              this.state.branches.map((branch) => {
                return (<option value={branch.id} key={`branch_${branch.id}`}>{branch.title}</option>)
              })
            }
          </select>
          <br />
          <br />
          <button onClick={this.handleSaveBranchClick.bind(this)}>Сохранить</button>
          <button onClick={this.handleCancelBranchClick.bind(this)}>Отменить</button>
        </td>
      )
    }
    
    return (
      <td onClick={this.handleCellBranchClick.bind(this)}>
        {this.state.branches.find((branch) => branch.id === this.state.device?.branchId)?.title ?? ''}
      </td>
    )
  }
  
  renderCommentCell() {
    if (this.state.isEditingComment) {
      return (
        <td>
          <textarea onChange={this.handleCommentChange.bind(this)} value={this.state.commentText ?? ''} />
          <br />
          <button onClick={this.handleSaveCommentClick.bind(this)}>Сохранить</button>
          <button onClick={this.handleCancelCommentClick.bind(this)}>Отменить</button>
        </td>
      )
    }
    
    return (
      <td onClick={this.handleCellCommentClick.bind(this)}>
        {this.state.device?.comment}
      </td>
    )
  }
  
  reloadDevice() {
    api.devicesGet(this.props.deviceId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          device: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  reloadBranches() {
    api.branchesList(1).then(
      (result) => {
        this.setState({
          branches: result,
        })
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  
  handleCellCommentClick() {
    if (this.state.isEditingComment) {
      return
    }
    
    this.setState({
      isEditingComment: true,
      commentText: this.state.device?.comment,
    })
  }
  
  handleCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      commentText: e.currentTarget.value
    })
  }
  
  handleCancelCommentClick() {
    this.setState({
      isEditingComment: false,
      commentText: undefined,
    })
  }
  
  handleSaveCommentClick() {
    let comment: string = this.state.commentText ?? ''
    api.devicesUpdateComment(this.props.deviceId, comment).then(
      (_result) => {
        this.setState({
          isEditingComment: false,
          commentText: undefined,
        })
        
        this.reloadDevice()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  
  handleCellBranchClick() {
    this.setState({
      isEditingBranch: true,
      selectedBranchId: this.state.device?.branchId,
    })
  }
  
  handleCancelBranchClick() {
    this.setState({
      isEditingBranch: false,
      selectedBranchId: undefined,
    })
  }
  
  handleBranchChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      selectedBranchId: +e.currentTarget.value
    })
  }
  
  handleSaveBranchClick() {
    let branchId: number = this.state.selectedBranchId ?? 0
    api.devicesUpdateBranch(this.props.deviceId, branchId).then(
      (_result) => {
        this.setState({
          isEditingBranch: false,
          selectedBranchId: undefined,
        })
        
        this.reloadDevice()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }

  async handleFirmwareDownload() {
    let fileUrl = await api.downloadFirmwareFile(this.props.deviceId)
    let a = document.createElement('a')
    a.download = `fw_${this.props.deviceId}.hex`
    a.href = fileUrl
    a.click()
  }
}

export default DeviceInfo
