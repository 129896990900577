let config = {
    deviceOfflineTimeWarning: 30 * 60,
    deviceOfflineTimeError: 6 * 60 * 60,
    deviceBatteryPreWarning: 50,
    deviceBatteryWarning: 30,
    deviceBatteryError: 20,
    
    authTokenRefreshTimeBefore: 10 * 60,
    
    apiServerEnv: process.env.API_SERVER_ENV ?? 'prod',
    adminUrl: process.env.ADMIN_URL ?? 'https://admin.tenzio.ru/',
    qrCodeLink: process.env.DEVICE_QR_LINK ?? 'https://app.tenzio.ru/qr/%deviceId%',
    ymapsKey: process.env.YMAPS_KEY ?? '57d4451b-74c1-41aa-9a3a-d83ac07ead2f',
    paymentsFileUrl: process.env.PAYMENTS_FILE_URL ?? 'https://702821.selcdn.ru/tenzio-payments/%key%.csv',
    
    deviceIdMinLength: 4,
    
    itemsPerPage: 50,
}

export default config
