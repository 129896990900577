import React from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'

import BranchRootScreen from '../branch/branch-root.screen'
import AdminRootScreen from '../admin/admin-root.screen'

import { Account, AccountKind } from '../../lib/entities'
import api from '../../lib/api'

interface ModelIdMatchParams {
  id: string
}

interface IProps extends RouteComponentProps {
  account: Account
}

interface IState {}

class RootScreen extends React.Component<IProps, IState> {
  render() {
    return (
      <div>
        <Switch>
          <Route path={`/branch/:id`} render={this.renderBranchMatch.bind(this)}></Route>
          <Route path={`/admin`} render={this.renderAdminRoot.bind(this)}></Route>
          <Route path={`/`} render={this.renderHomeScreen.bind(this)}></Route>
        </Switch>
      </div>
    )
  }
  
  renderBranchMatch({match}: RouteComponentProps<ModelIdMatchParams>) {
    return this.renderBranchRoot(+match.params.id)
  }
  
  renderBranchRoot(branchId: number) {
    return <BranchRootScreen branchId={branchId} account={this.props.account} /> 
  }
  
  renderAdminRoot() {
    return <AdminRootScreen account={this.props.account} />
  }
  
  renderHomeScreen() {
    if (this.props.account.kind === AccountKind.superadmin) {
      return (
        <Switch>
          <Route path={`/device/:id`} render={this.renderDeviceMatch.bind(this)}></Route>
          <Route path={`/`}><Redirect to={`/admin`} /></Route>
        </Switch>
      )
    }
    
    if (this.props.account.branchId > 0) {
      return this.renderBranchRoot(this.props.account.branchId)
    }
    
    return <div>Invalid user</div>
  }
  
  renderDeviceMatch({match}: RouteComponentProps<ModelIdMatchParams>) {
    this.reloadDeviceScreenFor(match.params.id)
    return <Redirect to={`/admin`} />
  }
  
  reloadDeviceScreenFor(deviceId: string) {
    api.branchGetByDeviceId(deviceId).then(
      (result) => {
        this.props.history.replace(`/branch/${result.id}/device/${deviceId}`)
      }, 
      (error) => {
        toast.error(error)
      }
    )
  }
}

export default withRouter(RootScreen)
