import React from 'react'

import { Card } from '../../lib/entities'
import { dateString } from '../../lib/helpers'
import api from '../../lib/api'

interface IProps { 
  accountId: string
}

interface IState { 
  cards: Card[]
  isLoaded: boolean
  error: Error | null
}

class CardsList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      cards: [],
      isLoaded: false,
      error: null,
    }
  }
  
  componentDidMount() {
    this.reloadCards()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    if (this.state.cards.length < 1) {
      return <div><p>У пользователя нет сохраненных банковских карт</p></div>
    }
    
    return (
      <div>
        <h4>Saved cards</h4>
        <div className='table-container'>
          <table>
            <thead>{this.renderHeader()}</thead>
            <tbody>{this.state.cards.map((card) => this.renderCardLine(card))}</tbody>
          </table>
        </div>
      </div>
    )
  }
  
  renderHeader() {
    return (
      <tr>
        <th>Последние 4 цифры</th>
        <th>Режим</th>
        <th>Дата добавления</th>
      </tr>
    )
  }
  
  renderCardLine(card: Card) {
    return (
      <tr key={`card_${card.id}`}>
        <td>*{card.lastFour}</td>
        <td>{card.isSandbox ? 'тестовый' : 'боевой'}</td>
        <td>{dateString(new Date(card.addedDate))}</td>
      </tr>
    )
  }
  
  
  reloadCards() {
    this.setState({
      isLoaded: false,
      error: null,
    })
    
    api.cardsGetForAccount(this.props.accountId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          cards: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default CardsList
