import React from 'react'
import { toast } from 'react-toastify'

import api from '../../lib/api'

interface IProps { 
  onAuth: (authToken: string, refreshToken: string) => void
}

interface IState { 
  login: string
  pass: string
}

class LoginForm extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    
    this.state = { 
      login: "", 
      pass: "",
    }
  }
  
  render() {
    return (
      <div>
        <h4>Войти в админку</h4>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <p>
            Логин
            &nbsp;
            <input type='text' value={this.state.login} onChange={this.handleLoginChange.bind(this)} />
          </p>
          <p>
            Пароль
            &nbsp;
            <input type='password' value={this.state.pass} onChange={this.handlePassChange.bind(this)} />
          </p>
          <p>
            <input type='submit' value='Войти' />
          </p>
        </form>
      </div>
    )
  }
  
  handleLoginChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({login: e.target.value})
  }
  
  handlePassChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({pass: e.target.value})
  }
  
  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    
    api.authLogin(this.state.login, this.state.pass, true).then(
      (result) => {
        this.props.onAuth(result.authToken, result.refreshToken)
      },
      (error) => {
        toast.error(`Error: ${error.message}`)
      }
    )
  }
}

export default LoginForm
