import React from 'react'
import { NavLink, Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom'
import fixUrl from '../../../lib/utils/fix-url'

interface ModelIdMatchParams {
  id: string
}

interface IProps extends RouteComponentProps { }

class MenuPanel extends React.Component<IProps> {
  render() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div className='menu-panel'>
        <NavLink activeClassName='is-active' to={`${url}/branches`}>Бренчи</NavLink>
        <NavLink activeClassName='is-active' to={`${url}/users`}>Пользователи</NavLink>
        
        <Switch>
          <Route path={`${path}/user/:id`} render={this.renderUserControl.bind(this)} />
        </Switch>
      </div>
    )
  }

  renderUserControl({match}: RouteComponentProps<ModelIdMatchParams>) {
    let url = fixUrl(this.props.match.url)
    let userId = match.params.id
    return <NavLink activeClassName='is-active' to={`${url}/user/${userId}`}>Пользователь {userId}</NavLink>
  }
}

export default withRouter(MenuPanel)
