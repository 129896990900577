import React from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import LoginForm from '../components/auth/login-form'

import state from '../lib/state'

interface IProps extends RouteComponentProps { }
interface IState { }

class AuthScreen extends React.Component<IProps, IState> {
  render() {
    return (
      <div>
        <LoginForm onAuth={this.handleAuth.bind(this)} />
      </div>
    )
  }
  
  handleAuth(authToken: string, refreshToken: string) {
    state.authToken = authToken
    state.refreshToken = refreshToken
    state.save()
    
    this.props.history.push('/')
  }
}

export default withRouter(AuthScreen)
