import React from 'react'

import config from '../../lib/config'

interface IProps {
  percent?: number
}

interface IState { }

class DeviceBatteryCell extends React.Component<IProps, IState> {
  render() {
    return (
      <td className={this.renderClass()}>
        {this.props.percent ? `${this.props.percent}%` : 'n/a'}
      </td>
    )
  }
  
  renderClass(): string {
    if (!this.props.percent || this.props.percent < config.deviceBatteryError) {
      return 'cell-error'
    }
    
    if (this.props.percent < config.deviceBatteryWarning) {
      return 'cell-warning'
    }
    
    return 'cell-ok'
  }
}

export default DeviceBatteryCell
