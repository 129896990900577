import { Link } from 'react-router-dom'

import EntitiesList from '../prototypes/list'

import { Ride } from '../../lib/entities'
import { dateString, dateForTime } from '../../lib/helpers'
import api from '../../lib/api'

class RidesList<TProps> extends EntitiesList<TProps> {
  linkPathRoot(): string {
    return '.'
  }
  
  renderHeader() {
    return (
      <tr>
        <th>ID</th>
        <th>Самокат</th>
        <th>Время</th>
        <th>Сумма</th>
      </tr>
    )
  }
  
  renderLine(ride: Ride) {
    return (
      <tr key={`ride_${ride.id}`}>
        <td><Link to={`${this.linkPathRoot()}/ride/${ride.id}`}>{ride.id}</Link></td>
        <td>{ride.deviceId}</td>
        <td>
          {dateString(dateForTime(ride.startTime))}
          <br />
          {ride.endTime ? dateString(dateForTime(ride.endTime)) : 'n/a'}
        </td>
        <td>{ride.totalAmount}</td>
      </tr>
    )
  }
  
  callApiList(page: number) {
    return api.ridesList(page)
  }
  
  callApiCount() {
    return api.ridesCount()
  }
}

export default RidesList
