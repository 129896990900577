import AccountsList from './list'

import api from '../../lib/api'

class AdminAccountsList<TProps> extends AccountsList<TProps> {
  callApiList(page: number) {
    return api.accountsList(page, { adminOnly: true })
  }
  
  callApiCount() {
    return api.accountsCount({ adminOnly: true })
  }
}

export default AdminAccountsList
