import React from 'react'

import { Account, AccountKind, AccountKindUtil } from '../../lib/entities'
import { dateString } from '../../lib/helpers'
import api from '../../lib/api'

interface IProps {
  accountId: string
}

interface IState { 
  account: Account
  isLoaded: boolean
  error: Error | null
}

class AccountInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    
    this.state = {
      account: {
        id: '',
        login: '',
        createdDate: '',
        isRegistered: false,
        kind: AccountKind.anonymous,
        branchId: 0,
      },
      isLoaded: false,
      error: null,
    }
  }
  
  componentDidMount() {
    this.reloadAccount()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div>Loading...</div>
    }
    
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>
    }
    
    return (
      <div>
        <h3>{this.state.account.login}</h3>
        <div className='table-container'>
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{this.state.account.id}</td>
              </tr>
              <tr>
                <td>Логин</td>
                <td>{this.state.account.login}</td>
              </tr>
              <tr>
                <td>Тип (права доступа)</td>
                <td>{AccountKindUtil.toString(this.state.account.kind)}</td>
              </tr>
              <tr>
                <td>Регистрация</td>
                <td>{this.state.account.isRegistered ? 'зарегистрирован' : 'нет'}</td>
              </tr>
              <tr>
                <td>Дата создания</td>
                <td>{dateString(new Date(this.state.account.createdDate))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  
  
  reloadAccount() {
    this.setState({
      isLoaded: false,
      error: null,
    })
    
    api.accountGet(this.props.accountId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          account: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default AccountInfo
