import React from 'react'
import { withRouter, Switch, Route, NavLink, RouteComponentProps } from 'react-router-dom'

import DevicesList from '../../components/devices/list'
import DevicesAdd from '../../components/devices/add'

import { Branch, Account, AccountKind } from '../../lib/entities'
import fixUrl from '../../lib/utils/fix-url'

interface IProps extends RouteComponentProps {
  branch: Branch
  account: Account
}
interface IState {}

class DevicesScreen extends React.Component<IProps, IState> {
  render() {
    let path = fixUrl(this.props.match.path)
    return (
      <div className='section'>
        { this.props.account.kind === AccountKind.superadmin ? this.renderSuperadminMenu() : '' }
        <div className='content'>
          <Switch>
            <Route path={`${path}/new`}><DevicesAdd /></Route>
            <Route path={`${path}/`}><DevicesList branch={this.props.branch} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
  
  renderSuperadminMenu() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <div className='menu white'>
            <div className='menu-panel'>
              <NavLink activeClassName='is-active' to={`${url}/new`}>Создать новый</NavLink>
            </div>
          </div>
        </Route>
      </Switch>
    )
  }
}

export default withRouter(DevicesScreen)
