import React from 'react'
import { Link } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'

import { Payment } from '../../lib/entities'
import { dateString } from '../../lib/helpers'
import api from '../../lib/api'

interface IProps { 
  accountId: string
}

interface IState { 
  payments: Payment[]
  isLoaded: boolean
  error: Error | null
  page: number
  pagesCount: number
}

class PaymentsList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      payments: [],
      isLoaded: false,
      error: null,
      page: 1,
      pagesCount: 1,
    }
  }
  
  componentDidMount() {
    this.reloadPayments()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    if (this.state.payments.length < 1) {
      return (
        <div>
          <div className='paginator-container'>
            <Pagination page={this.state.page} count={this.state.pagesCount} onChange={this.handleChangePage.bind(this)} />
          </div>
          <p>Пользователь пока не совершал платежей</p>
        </div>
      )
    }
    
    return (
      <div>
        <div className='paginator-container'>
          <Pagination page={this.state.page} count={this.state.pagesCount} onChange={this.handleChangePage.bind(this)} />
        </div>
        <div className='table-container'>
          <table>
            <thead>{this.renderHeader()}</thead>
            <tbody>{this.state.payments.map((payment) => this.renderPaymentLine(payment))}</tbody>
          </table>
        </div>
      </div>
    )
  }
  
  renderHeader() {
    return (
      <tr>
        <th>ID</th>
        <th>Дата</th>
        <th>Режим</th>
        <th>Сумма</th>
        <th>Статус</th>
      </tr>
    )
  }
  
  renderPaymentLine(payment: Payment) {
    return (
      <tr key={`payment_${payment.id}`}>
        <td><Link to={`/payment/${payment.id}`}>{payment.id}</Link></td>
        <td>{dateString(new Date(payment.date))}</td>
        <td>{payment.isSandbox ? 'тестовый' : 'боевой'}</td>
        <td>{payment.amount}</td>
        <td>{payment.state}</td>
      </tr>
    )
  }
  
  
  reloadPayments(page: number = 1) {
    this.setState({
      isLoaded: false,
      error: null,
      page: page,
    })
    
    api.paymentsGetForAccount(this.props.accountId, page).then(
      (result) => {
        this.setState({
          isLoaded: true,
          payments: result,
          page: page,
        })
        
        if (page === 1) {
          this.reloadPaymentsCount()
        }
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  reloadPaymentsCount() {
    api.paymentsCountForAccount(this.props.accountId).then(
      (result) => {
        this.setState({
          pagesCount: api.pageCountFor(result.count ?? 0)
        })
      }
    )
  }
  
  handleChangePage(_event: object, page: number) {
    this.reloadPayments(page)
  }
}

export default PaymentsList
