import { Device, DeviceInfo, DeviceLocation, RiderInfo } from './entities'

export enum LoginMode {
    phone, email
}

export interface DevicePoint {
  device: Device
  info: DeviceInfo
  rider?: RiderInfo
  location: DeviceLocation
}

export enum DeviceMode {
  live = 'live',
  service = 'service',
  garage = 'garage',
}

export enum BranchKind {
  scooters = 'scooters',
  milk = 'milk',
}
