import React from 'react'

import DevicesMap from '../../components/devices/map'

import { Branch } from '../../lib/entities'

interface IProps {
  branch: Branch
}

class MapScreen extends React.Component<IProps> {
  render() {
    return (
      <div className='section'>
        <div className='content'>
          <DevicesMap branch={this.props.branch} />
        </div>
      </div>
    )
  }
}

export default MapScreen
