import React from 'react'

import PaymentInfo from '../components/payments/info'

interface IProps {
  paymentId: number
}

class PaymentInfoScreen extends React.Component<IProps> {
  render() {
    return (
      <div className='section'>
        <div className='content'>
          <h3>Платеж {this.props.paymentId}</h3>
          <PaymentInfo paymentId={this.props.paymentId} />
        </div>
      </div>
    )
  }
}  

export default PaymentInfoScreen
