import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import api from '../../lib/api'

interface IProps extends RouteComponentProps {
  deviceId: string
}

interface IState {
  enteredDeviceId: string
}

class RemoveDevice extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      enteredDeviceId: ''
    }
  }
  
  linkPathRoot(): string {
    return '../..'
  }
  
  render() {
    return (
      <div>
        <h4>Введите номер самоката, чтобы безвозвратно его удалить</h4>
        <p>
          <input type='text' value={this.state.enteredDeviceId} onChange={this.handleDeviceIdChange.bind(this)} />
        </p>
        <p>
          <button disabled={this.state.enteredDeviceId !== this.props.deviceId} onClick={this.handleRemove.bind(this)}>Удалить безвозвратно</button>
        </p>
      </div>
    )
  }
  
  handleDeviceIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      enteredDeviceId: e.currentTarget.value
    })
  }
  
  handleRemove() {
    api.devicesRm(this.props.deviceId).then(
      (result) => {
        toast.success('Самокат удален')
        this.props.history.push(`${this.linkPathRoot()}/devices`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(RemoveDevice)
