import React from 'react'

interface IProps {
  text?: string
  onChange: (text: string, onSave: () => void) => void
}

interface IState { 
  isEditing: boolean
  editedText: string
}

class TextFieldCell extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isEditing: false,
      editedText: '',
    }
  }
  
  render() {
    if (!this.state.isEditing) {
      return (
        <td onClick={this.handleCellClick.bind(this)}>
          {this.props.text}
        </td>
      )
    }
    
    return (
      <td>
        <textarea onChange={this.handleTextChange.bind(this)} value={this.state.editedText} />
        <br />
        <button onClick={this.handleSaveClick.bind(this)}>Сохранить</button>
        <button onClick={this.handleCancelClick.bind(this)}>Отменить</button>
      </td>
    )
  }
  
  
  handleCellClick() {
    if (this.state.isEditing) {
      return
    }
    
    this.setState({
      isEditing: true,
      editedText: this.props.text ?? '',
    })
  }
  
  handleTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      editedText: e.currentTarget.value
    })
  }
  
  handleCancelClick() {
    this.setState({
      isEditing: false,
      editedText: '',
    })
  }
  
  handleSaveClick() {
    if (!this.state.editedText) {
      this.handleCancelClick()
      return
    }
    
    this.props.onChange(this.state.editedText, () => {
      this.setState({
        isEditing: false,
        editedText: '',
      })
    })
  }
}

export default TextFieldCell
