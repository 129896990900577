import React from 'react'
import { YMaps, Map, Placemark, ZoomControl, GeolocationControl } from 'react-yandex-maps'

import { DeviceLocation } from '../../lib/entities'
import { dateString, dateForTime } from '../../lib/helpers'
import api from '../../lib/api'
import config from '../../lib/config'

interface IProps {
  deviceId: string
}

interface IState {
  isLoaded: boolean
  error: Error | null
  deviceLocation: DeviceLocation
}

class DeviceOnMap extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      error: null,
      deviceLocation: {
        deviceId: '0000',
        lat: 0, 
        lng: 0, 
        time: 0,
      }
    }
  }
  
  componentDidMount() {
    this.reloadData()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<div>Loading...</div>)
    }
    
    if (this.state.error) {
      return (<div>Error: {this.state.error.message}</div>)
    }
    
    let location = this.state.deviceLocation
    if (!location) {
      return (<div>Самокат пока не передавал своих координат</div>)
    }
    
    return (
      <div className='device-map'>
        <h4>Обновлено в {dateString(dateForTime(location.time))}</h4>
        <YMaps query={{ apikey: config.ymapsKey }}>
          <Map 
            defaultState={{ center: [location.lat, location.lng], zoom: 16 }} 
            width='100%' 
            height='500px' 
            modules={['geolocation']}
          >
            <ZoomControl />
            <GeolocationControl />
            <Placemark geometry={[location.lat, location.lng]} />
          </Map>
        </YMaps>
      </div>
    )
  }
  
  reloadData() {
    api.devicesGetLocation(this.props.deviceId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          deviceLocation: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default DeviceOnMap
