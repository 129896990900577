import { Link } from 'react-router-dom'

import EntitiesList from '../prototypes/list'

import { Zone } from '../../lib/entities'
import api from '../../lib/api'

interface IProps { 
  branchId: number
}

class ZonesList extends EntitiesList<IProps> {
  linkPathRoot(): string {
    return '.'
  }
  
  renderHeader() {
    return (
      <tr>
        <th>ID</th>
        <th>Название</th>
      </tr>
    )
  }
  
  renderLine(zone: Zone) {
    return (
      <tr key={`zone_${zone.id}`}>
        <td><Link to={`${this.linkPathRoot()}/zone/${zone.id}`}>{zone.id}</Link></td>
        <td>{zone.title}</td>
      </tr>
    )
  }
  
  callApiList(page: number) {
    return api.zonesList(page, this.props.branchId)
  }
  
  callApiCount() {
    return api.zonesCount(this.props.branchId)
  }
}

export default ZonesList
