import React from 'react'

import { Account } from '../lib/entities'
import state from '../lib/state'
import api from '../lib/api'

interface IProps {
    account: Account
}
interface IState { }

class UserPanel extends React.Component<IProps, IState> {
  render() {
    return (
      <div className='user-panel'>
        <span>{this.props.account.login}</span>
        &nbsp;&nbsp;
        <button onClick={this.handleLogout.bind(this)}>Выйти</button>
      </div>
    )
  }
  
  handleLogout() {
    state.authToken = undefined
    state.refreshToken = undefined
    state.save()
    
    api.authToken = undefined
    api.refreshToken = undefined
    
    window.location.reload()
  }
}

export default UserPanel
