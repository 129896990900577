import React from 'react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import RideInfo from '../components/ride-info/info'
import RidePayments from '../components/ride-info/payments'
import RideMap from '../components/ride-info/map'

interface IProps extends RouteComponentProps {
  rideId: string
}
interface IState { }

class RideInfoScreen extends React.Component<IProps, IState> {
  render() {
    let url = this.props.match.url
    return (
      <div className='section'>
        <h3>Поездка {this.props.rideId}</h3>
        <div className='menu'>
          <div className='menu-panel'>
            <NavLink activeClassName='is-active' to={`${url}`} exact={true}>Инфо</NavLink>
            <NavLink activeClassName='is-active' to={`${url}/payments`}>Платежи</NavLink>
            <NavLink activeClassName='is-active' to={`${url}/map`}>На карте</NavLink>
          </div>
        </div>
        <div className='content'>
          <Switch>
            <Route path={`${url}/payments`}><RidePayments rideId={this.props.rideId} /></Route>
            <Route path={`${url}/map`}><RideMap rideId={this.props.rideId} /></Route>
            <Route path={`${url}`}><RideInfo rideId={this.props.rideId} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
}  

export default withRouter(RideInfoScreen)
