import React from 'react'

import BranchInfo from '../../components/branch-info/info'

import { Branch, Account } from '../../lib/entities'

interface IProps {
  branch: Branch
  account: Account
}

class ConfigScreen extends React.Component<IProps> {
  render() {
    return (
      <div className='section'>
        <div className='content'>
          <BranchInfo branchId={this.props.branch.id} account={this.props.account} />
        </div>
      </div>
    )
  }
}

export default ConfigScreen
