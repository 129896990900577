import RidesList from '../rides/list'

import api from '../../lib/api'

interface IProps { 
  branchId: number
}

class BranchRides extends RidesList<IProps> {
  linkPathRoot(): string {
    return '.'
  }
  
  callApiList(page: number) {
    return api.ridesListForBranch(this.props.branchId, page)
  }
  
  callApiCount() {
    return api.ridesCountForBranch(this.props.branchId)
  }
}

export default BranchRides
