import React from 'react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import ZonesList from '../../components/zones/list'
import ZoneAdd from '../../components/zones/add'

import { Branch } from '../../lib/entities'
import fixUrl from '../../lib/utils/fix-url'

interface IProps extends RouteComponentProps { 
  branch: Branch
}

class ZonesScreen extends React.Component<IProps> {
  render() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div className='section'>
        <Switch>
          <Route path={`${path}/`} exact>
            <div className='menu white'>
              <div className='menu-panel'>
                <NavLink activeClassName='is-active' to={`${url}/new`}>Создать новую</NavLink>
              </div>
            </div>
          </Route>
        </Switch>
        <div className='content'>
          <Switch>
            <Route path={`${path}/new`}><ZoneAdd branchId={this.props.branch.id} /></Route>
            <Route path={`${path}/`}><ZonesList branchId={this.props.branch.id} /></Route> 
          </Switch>
        </div>
      </div>
    )
  }
}  

export default withRouter(ZonesScreen)
