import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import api from '../../lib/api'

interface IProps extends RouteComponentProps {
  zoneId: number
}

interface IState {
  enteredZoneId: string
}

class RemoveZone extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      enteredZoneId: ''
    }
  }
  
  render() {
    return (
      <div>
        <h4>Введите номер зоны, чтобы безвозвратно ее удалить</h4>
        <p>
          <input type='text' value={this.state.enteredZoneId} onChange={this.handleDeviceIdChange.bind(this)} />
        </p>
        <p>
          <button disabled={this.state.enteredZoneId !== `${this.props.zoneId}`} onClick={this.handleRemove.bind(this)}>Удалить безвозвратно</button>
        </p>
      </div>
    )
  }
  
  handleDeviceIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      enteredZoneId: e.currentTarget.value
    })
  }
  
  handleRemove() {
    api.zonesRm(this.props.zoneId).then(
      (result) => {
        toast.success('Зона удалена')
        this.props.history.push('/zones')
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(RemoveZone)
