import React from 'react'

import config from '../../lib/config'
import { getTime, dateForTime, dateString } from '../../lib/helpers'

interface IProps {
  time?: number
}

interface IState { }

class DeviceSeenTimeCell extends React.Component<IProps, IState> {
  render() {
    return (
      <td className={this.renderClass()}>
        {this.props.time ? dateString(dateForTime(this.props.time)) : 'нет'}
      </td>
    )
  }
  
  renderClass(): string {
    let now = getTime()
    if (!this.props.time || this.props.time < now - config.deviceOfflineTimeError) {
      return 'cell-error'
    }
    
    if (this.props.time < now - config.deviceOfflineTimeWarning) {
      return 'cell-warning'
    }
    
    return 'cell-ok'
  }
}

export default DeviceSeenTimeCell
