import AccountsList from '../accounts/list'

import api from '../../lib/api'

interface IProps {
  branchId: number
}

class BranchAccountsList extends AccountsList<IProps> {
  callApiList(page: number) {
    return api.accountsList(page, { branchId: this.props.branchId })
  }
  
  callApiCount() {
    return api.accountsCount({ branchId: this.props.branchId })
  }
}

export default BranchAccountsList
