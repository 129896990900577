import React from 'react'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import { Branch, AccountKind, AccountKindUtil } from '../lib/entities'

interface IProps extends RouteComponentProps {
  accountKind: AccountKind
  branch?: Branch
}

interface IState { 
  enteredDeviceId?: string
}

class RootMenuPanel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {}
  }
  
  render() {
    return (
      <div className='menu-panel'>
        {
          this.props.branch 
            ? <NavLink activeClassName='is-active' to='/'>{this.titleFor(this.props.branch)}</NavLink> 
            : ''
        }
        { 
          AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.superadmin) 
            ? <NavLink activeClassName='is-active' to='/admin'>Суперадмин</NavLink>
            : ''
        }
        {this.renderDeviceIdSelector()}
      </div>
    )
  }
  
  titleFor(branch: Branch) {
    if (!branch.title || branch.title.length < 1) {
      return `Branch ${branch.id}`
    }
    
    return branch.title
  }
  
  renderDeviceIdSelector() {
    return (
      <div className='deviceIdMenuForm'>
        <form onSubmit={this.handleDeviceIdSubmit.bind(this)}>
          <input type='text' placeholder='2000' inputMode='numeric' onChange={this.handleDeviceIdChange.bind(this)}/>
          &nbsp;
          <input type='submit' value='Go' hidden={!this.state.enteredDeviceId} />
        </form>
      </div>
    )
  }
  
  handleDeviceIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      enteredDeviceId: e.currentTarget.value
    })
  }
  
  handleDeviceIdSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (!this.state.enteredDeviceId) {
      e.preventDefault()
      return
    }
    
    this.props.history.push(`/device/${this.state.enteredDeviceId}`)
  }
}

export default withRouter(RootMenuPanel)
