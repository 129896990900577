import { Device, DeviceInfo, RiderInfo } from '../../../lib/entities'
import { DeviceMode } from '../../../lib/types'
import { getTime } from '../../../lib/helpers'
import api from '../../../lib/api'
import config from '../../../lib/config'

type DeviceInfoDic = {[key: string]: DeviceInfo}
type RiderInfoDic = {[key: string]: RiderInfo}

export type EnrichedDevice = {
  device: Device,
  deviceInfo?: DeviceInfo,
  riderInfo?: RiderInfo,
}

type LFCache = { 
  enrichedDevices: EnrichedDevice[],
  updateTime: number,
}

export enum ListFilter {
  all, live, service, lowBattery, offline
}

export default class ListHelper
{
  private branchId: number
  private cache?: LFCache
  
  constructor(branchId: number) {
    this.branchId = branchId
  }
  
  public async updateData(filter: ListFilter = ListFilter.all): Promise<EnrichedDevice[]>
  {
    if (!this.cache) {
      this.cache = await this.reloadCache()
    }
    
    return this.applyFilter(filter, this.cache)
  }
  
  private async reloadCache(): Promise<LFCache> {
    let updateTime: number = getTime()
    let devices = await this.loadAllDevices(this.branchId)
    let infoDics = await this.reloadInfoDicsFor(devices)
    
    let enrichedDevices: EnrichedDevice[] = devices.map(device => {
      return {
        device, 
        deviceInfo: infoDics.deviceInfoDic[device.id],
        riderInfo: infoDics.riderInfoDic[device.id],
      }
    })
    
    return { enrichedDevices, updateTime }
  }
  
  private async loadAllDevices(branchId: number): Promise<Device[]> {
    let allDevices: Device[] = []
    let page = 1
    
    do
    {
      let devices: Device[] = await api.devicesList(page, { branchId: branchId })
      if (devices.length < 1) {
        break
      }
      
      allDevices = allDevices.concat(devices)
      page += 1
    } while (page < 100)
    
    return allDevices
  }
  
  private async reloadInfoDicsFor(devices: Device[]): Promise<{ deviceInfoDic: DeviceInfoDic, riderInfoDic: RiderInfoDic }> {
    let deviceIds: string[] = devices.map(device => device.id)
    if (deviceIds.length < 1) {
      return { deviceInfoDic: {}, riderInfoDic: {} }
    }
    
    let result = await api.devicesListInfo(deviceIds)
    
    let deviceInfos: DeviceInfo[] = result.deviceInfos
    let deviceInfoDic: DeviceInfoDic = {}
    deviceInfos.forEach((info) => {
      deviceInfoDic[info.deviceId] = info
    })
    
    let riderInfos: RiderInfo[] = result.riderInfos
    let riderInfoDic: RiderInfoDic = {}
    riderInfos.forEach((info) => {
      riderInfoDic[info.deviceId] = info
    })
    
    return { deviceInfoDic, riderInfoDic }
  }
  
  private async applyFilter(filter: ListFilter, cache: LFCache): Promise<EnrichedDevice[]> {
    return cache.enrichedDevices.filter(enrichedDevice => {
      switch (filter) {
        case ListFilter.live: return enrichedDevice.device.mode === DeviceMode.live
        case ListFilter.service: return enrichedDevice.device.mode === DeviceMode.service
        case ListFilter.lowBattery: return enrichedDevice.deviceInfo && enrichedDevice.deviceInfo.battery < config.deviceBatteryWarning
        case ListFilter.offline: return !enrichedDevice.deviceInfo || enrichedDevice.deviceInfo.time < cache.updateTime - config.deviceOfflineTimeWarning
      }
      
      return true
    })
  }
  
  
  static filterTitle(filter: ListFilter): string {
    switch (filter) {
      case ListFilter.all: return 'Все'
      case ListFilter.lowBattery: return 'Разряженные'
      case ListFilter.offline: return 'Оффлайн'
      case ListFilter.live: return 'В поле'
      case ListFilter.service: return 'В сервисе'
      default: return ''
    }
  }
  
  static availableFilters(): ListFilter[] {
    return [ ListFilter.all, ListFilter.live, ListFilter.service, ListFilter.lowBattery, ListFilter.offline ]
  }
}
