import React from 'react'
//import { toast } from 'react-toastify'

import DeviceBatteryCell from '../devices/device-battery-cell'
import DeviceSeenTimeCell from '../devices/device-seentime-cell'

import { DeviceInfo, DevicePower, Branch, Device } from '../../lib/entities'
import { DeviceMode } from '../../lib/types'
import api from '../../lib/api'

interface IProps {
  device: Device | null
  deviceInfo: DeviceInfo | null
  devicePower: DevicePower | null
}

interface IState { 
  branches: Branch[]
}

class DeviceState extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      branches: [],
    }
  }
  
  componentDidMount() {
    this.reloadBranches()
  }
  
  render() {
    if (!this.props.deviceInfo) {
      return (
        <div>
          Самокат пока ни разу не передавал свое состояние
        </div>
      )
    }
    
    return (
      <div>
        <h4>Состояние</h4>
        <div className='table-container'>
          <table>
            <tbody>
              <tr>
                <td>Питание</td>
                {this.props.devicePower?.on ? <td className='mode-live-inuse'>ВКЛЮЧЕН</td> : <td>выключен</td>}
              </tr>
              <tr>
                <td>Заряд батареи</td>
                <DeviceBatteryCell percent={this.props.deviceInfo?.battery ?? null} />
              </tr>
              <tr>
                <td>Время последнего апдейта</td>
                <DeviceSeenTimeCell time={this.props.deviceInfo?.time ?? null} />
              </tr>
              <tr>
                <td>Gartner ID</td>
                <td>{this.props.deviceInfo?.gartnerId}</td>
              </tr>
              <tr>
                <td>Бренч</td>
                <td>{this.renderBranch()}</td>
              </tr>
              <tr>
                <td>Режим</td>
                {this.renderModeCell(this.props.device)}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  
  renderBranch() {
    return this.state.branches.find((branch) => branch.id === this.props.device?.branchId)?.title ?? ''
  }
  
  renderModeCell(device: Device | null) {
    if (!device) {
      return ''
    }
    
    switch (device.mode) {
      case DeviceMode.garage:
        return <td className='mode-garage'>в гараже</td>
      
      case DeviceMode.service:
        return <td className='mode-service'>сервис</td>
      
      default: 
        return <td>В ПОЛЕ</td>
    }
  }
  
  reloadBranches() {
    api.branchesList(1).then(
      (result) => {
        this.setState({
          branches: result,
        })
      },
      (error) => {
        //toast.error(error.message)
      }
    )
  }
}

export default DeviceState
