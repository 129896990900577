import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import api from '../../lib/api'

interface IProps extends RouteComponentProps { }

interface IState { 
  title: string
}

class BranchAdd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      title: ''
    }
  }
  
  render() {
    return (
      <div>
        <h4>Название для нового бренча</h4>
        <p>
          <input type='text' value={this.state.title} onChange={this.handleTitleChange.bind(this)} />
        </p>
        <p>
          <button onClick={this.handleAdd.bind(this)}>Создать новый бренч</button>
        </p>
      </div>
    )
  }
  
  handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      title: e.currentTarget.value
    })
  }
  
  handleAdd() {
    let title = this.state.title.trim()
    if (title.length < 3) {
      toast.error(`Нужно ввести хотя бы 3 символа`)
      return
    }
    
    api.branchesAdd(title).then(
      (result) => {
        toast.success('Бренч создан')
        let branchId = +result.id
        this.props.history.push(`/branch/${branchId}`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(BranchAdd)
