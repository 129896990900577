import React from 'react'
import { withRouter, Switch, Route, NavLink, RouteComponentProps } from 'react-router-dom'

import AccountsList from '../../components/branch-info/accounts'
import AccountsAdd from '../../components/accounts/add'

import { Branch, Account } from '../../lib/entities'
import fixUrl from '../../lib/utils/fix-url'

interface IProps extends RouteComponentProps {
  branch: Branch,
  account: Account,
}

class UsersScreen extends React.Component<IProps> {
  render() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div className='section'>
        <Switch>
          <Route path={`${path}/`} exact>
            <div className='menu white'>
              <div className='menu-panel'>
                <NavLink activeClassName='is-active' to={`${url}/new`}>Создать нового</NavLink>
              </div>
            </div>
          </Route>
        </Switch>
        <div className='content'>
          <Switch>
            <Route path={`${path}/new`}><AccountsAdd account={this.props.account} branchId={this.props.branch.id} /></Route>
            <Route path={`${path}/`}><AccountsList branchId={this.props.branch.id} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(UsersScreen)
