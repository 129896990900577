import React, { Fragment } from 'react'
import { toast } from 'react-toastify'

import TextFieldCell from '../common/text-field-cell'

import { Branch, BranchConfig, Account, AccountKind } from '../../lib/entities'
import { BranchKind } from '../../lib/types'
import api from '../../lib/api'
import config from '../../lib/config'

interface IProps {
  branchId: number
  account: Account
}

interface IState {
  branch: Branch | null
  branchConfig: BranchConfig | null
  isLoaded: boolean
  error: Error | null
  
  selectedKind?: BranchKind
  isEditingKind: boolean
  
  chatId?: string
  isEditingChatId: boolean
  isShowingPaymentsUrl: boolean
}

class BranchInfo extends React.Component<IProps, IState> {
  availableBranchKinds: BranchKind[] = [ BranchKind.scooters, BranchKind.milk ]
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      branch: null,
      branchConfig: null,
      isLoaded: false,
      error: null,
      
      isEditingKind: false,
      
      chatId: '',
      isEditingChatId: false,
      isShowingPaymentsUrl: false,
    }
  }
  
  componentDidMount() {
    this.reloadEntity()
    this.reloadBranchConfig()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    if (!this.state.branch) {
      return <div><p>Error: branch not found</p></div>
    }
    
    let branch = this.state.branch
    return (
      <div>
        <div className='table-container'>
          <table>
            <tbody>
              <tr>
                <td>Название</td>
                <td>{branch.title}</td>
              </tr>
              <tr>
                <td>Текст предупреждения</td>
                <TextFieldCell text={branch.warningText} onChange={this.handleSaveWarningText.bind(this)} />
              </tr>
              <tr>
                <td>Текст удачной покупки</td>
                <TextFieldCell text={branch.purchaseText} onChange={this.handleSavePurchaseText.bind(this)} />
              </tr>
              <tr>
                <td>Режим</td>
                {this.renderKindCell()}
              </tr>
              <tr>
                <td>Текст в чеке</td>
                <TextFieldCell text={branch.orderText} onChange={this.handleSaveOrderText.bind(this)} />
              </tr>
              {this.renderOrganizationLinesIfNeeded()}
              <tr>
                <td>URL файла платежей</td>
                {this.renderPaymentsFileCell()}
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Конфиг</h4>
        <div className='table-container'>
          <table>
            <tbody>
              <tr>
                <td>
                  Идентификатор группы в Telegram<br /><br />
                  (добавьте бота <a href="https://t.me/tenzio_notifier_bot">@tenzio_notifier_bot</a> в группу<br />
                  и отправьте <b>/info</b>, чтобы узнать идентификатор)
                </td>
                {this.renderChatIdCell()}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  
  
  reloadEntity() {
    this.setState({
      isLoaded: false,
      error: null,
    })
    
    api.branchGet(this.props.branchId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          branch: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  reloadBranchConfig() {
    api.branchConfigGet(this.props.branchId).then(
      (result) => {
        this.setState({
          branchConfig: result,
        })
      },
      (_error) => {
      }
    )
  }
  
  
  renderPaymentsFileCell() {
    if (!this.state.branch || !this.state.branchConfig) {
      return (<td></td>)
    }
    
    if (!this.state.isShowingPaymentsUrl) {
      return (
        <td><button onClick={this.handleShowPaymentsUrlClick.bind(this)} >Показать</button></td>
      )
    }
    
    let fileKey: string = this.state.branchConfig.paymentsFileKey ?? ("" + this.state.branch.id)
    return (
      <td>{config.paymentsFileUrl.replaceAll('%key%', fileKey)}</td>
    )
  }
  
  
  renderKindCell() {//FIXME: make editable
    if (!this.state.branch) {
      return (<td></td>)
    }
    
    if (this.state.isEditingKind) {
      return (
        <td>
          <select onChange={this.handleKindChange.bind(this)} value={this.state.selectedKind}>
            {
              this.availableBranchKinds.map((kind) => {
                return (<option value={kind} key={`branch_${kind}`}>{this.titleForKind(kind)}</option>)
              })
            }
          </select>
          <br />
          <br />
          <button onClick={this.handleSaveKindClick.bind(this)}>Сохранить</button>
          <button onClick={this.handleCancelKindClick.bind(this)}>Отменить</button>
        </td>
      )
    }
    
    return (
      <td onClick={this.handleCellKindClick.bind(this)}>
        {this.titleForKind(this.state.branch.kind)}
      </td>
    )
  }
  
  
  renderOrganizationLinesIfNeeded() {
    let branch = this.state.branch
    if ((this.props.account.kind !== AccountKind.superadmin) || !branch) {
      return ''
    }
    
    return (
      <Fragment>
        <tr>
          <td>Название организации</td>
          <TextFieldCell text={branch.organizationName} onChange={this.handleSaveOrgName.bind(this)} />
        </tr>
        <tr>
          <td>ИНН организации</td>
          <TextFieldCell text={branch.organizationInn} onChange={this.handleSaveOrgInn.bind(this)} />
        </tr>
      </Fragment>
    )
  }
  
  
  handleSaveWarningText(text: string, onSuccess: () => void) {
    api.branchesUpdateWarningText(this.props.branchId, text).then(
      (_result) => {
        onSuccess()
        this.reloadEntity()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  handleSavePurchaseText(text: string, onSuccess: () => void) {
    api.branchesUpdatePurchaseText(this.props.branchId, text).then(
      (_result) => {
        onSuccess()
        this.reloadEntity()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  handleSaveOrderText(text: string, onSuccess: () => void) {
    api.branchesUpdateOrderText(this.props.branchId, text).then(
      (_result) => {
        onSuccess()
        this.reloadEntity()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  handleSaveOrgName(text: string, onSuccess: () => void) {
    api.branchesUpdateOrgName(this.props.branchId, text).then(
      (_result) => {
        onSuccess()
        this.reloadEntity()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  handleSaveOrgInn(text: string, onSuccess: () => void) {
    api.branchesUpdateOrgInn(this.props.branchId, text).then(
      (_result) => {
        onSuccess()
        this.reloadEntity()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  handleShowPaymentsUrlClick() {
    this.setState({ isShowingPaymentsUrl: true })
  }
  
  
  renderChatIdCell() {
    if (this.state.isEditingChatId) {
      return (
        <td>
          <input onChange={this.handleChatIdChange.bind(this)} value={this.state.chatId ?? ''} />
          <br />
          <button onClick={this.handleSaveChatIdClick.bind(this)}>Сохранить</button>
          <button onClick={this.handleCancelChatIdClick.bind(this)}>Отменить</button>
        </td>
      )
    }
    
    return (
      <td onClick={this.handleCellChatIdClick.bind(this)}>
        {this.state.branchConfig?.telegramChatId}
      </td>
    )
  }
  
  
  handleCellChatIdClick() {
    if (this.state.isEditingChatId) {
      return
    }
    
    this.setState({
      isEditingChatId: true,
      chatId: this.state.branchConfig?.telegramChatId,
    })
  }
  
  handleChatIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      chatId: e.currentTarget.value
    })
  }
  
  handleCancelChatIdClick() {
    this.setState({
      isEditingChatId: false,
      chatId: undefined,
    })
  }
  
  handleSaveChatIdClick() {
    let chatId: string = this.state.chatId ?? ''
    api.branchConfigUpdateTelegramChatId(this.props.branchId, chatId).then(
      (_result) => {
        this.setState({
          isEditingChatId: false,
          chatId: undefined,
        })
        
        this.reloadBranchConfig()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  
  handleCellKindClick() {
    this.setState({
      isEditingKind: true,
      selectedKind: this.state.branch?.kind,
    })
  }
  
  handleCancelKindClick() {
    this.setState({
      isEditingKind: false,
      selectedKind: undefined,
    })
  }
  
  handleKindChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      selectedKind: e.currentTarget.value as BranchKind
    })
  }
  
  handleSaveKindClick() {
    let kind: BranchKind = this.state.selectedKind ?? BranchKind.scooters
    api.branchesUpdateKind(this.props.branchId, kind).then(
      (_result) => {
        this.setState({
          isEditingKind: false,
          selectedKind: undefined,
        })
        
        this.reloadEntity()
      },
      (error) => {
        toast.error(`${error.message}`)
      }
    )
  }
  
  titleForKind(kind: BranchKind): string {
    switch (kind) {
      case BranchKind.scooters: return "Самокаты"
      case BranchKind.milk: return "Простые продажи"
    }
  }
}

export default BranchInfo
