import React from 'react'
import { Link } from 'react-router-dom'

import { Ride } from '../../lib/entities'
import { dateString, dateForTime } from '../../lib/helpers'
import api from '../../lib/api'

interface IProps {
  rideId: string
}

interface IState { 
  ride: Ride | null
  isLoaded: boolean
  error: Error | null
}

class RideInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      ride: null,
      isLoaded: false,
      error: null,
    }
  }
  
  componentDidMount() {
    this.reloadPayment()
  }
  
  linkPathRoot(): string { //FIXME: think on how to handle all the links
    return '..'
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    if (!this.state.ride) {
      return <div><p>Error: ride not found</p></div>
    }
    
    let ride = this.state.ride
    return (
      <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td>Самокат</td>
              <td><Link to={`${this.linkPathRoot()}/device/${ride.deviceId}`}>{ride.deviceId}</Link></td>
            </tr>
            <tr>
              <td>Пользователь</td>
              <td><Link to={`${this.linkPathRoot()}/user/${ride.accountId}`}>{ride.accountId}</Link></td>
            </tr>
            <tr>
              <td>Начало в</td>
              <td>{dateString(dateForTime(ride.startTime))}</td>
            </tr>
            <tr>
              <td>Конец в</td>
              <td>{ride.endTime ? dateString(dateForTime(ride.endTime)) : 'еще нет'}</td>
            </tr>
            <tr>
              <td>Итоговая сумма</td>
              <td>{ride.totalAmount}р</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  
  
  reloadPayment() {
    this.setState({
      isLoaded: false,
      error: null,
    })
    
    api.rideGet(this.props.rideId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          ride: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default RideInfo
