import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import PassGen from 'generate-password'

import { Account } from '../../lib/entities'

import api from '../../lib/api'

interface IProps extends RouteComponentProps { 
  accountId: string
}

interface IState {
  account?: Account
  isLoaded: boolean
  error?: Error
  ownPassword: string
  password: string
}

class AccountPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    let password: string = PassGen.generate({ numbers: true, length: 10 })
    this.state = {
      isLoaded: false,
      ownPassword: '',
      password: password,
    }
  }
  
  componentDidMount() {
    this.reloadAccount()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div>Loading...</div>
    }
    
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>
    }
    
    return (
      <div>
        <h4>Сбросить пароль</h4>
        <h5>
          для пользователя {this.state.account?.login}
        </h5>
        <p>
          <b>Ваш</b> текущий пароль
          &nbsp;
          <input type='password' value={this.state.ownPassword} onChange={this.handleOwnPasswordChange.bind(this)} />
        </p>
        <p>
          Новый пароль для <b>{this.state.account?.login}</b>
          &nbsp;
          <input type='text' value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
        </p>
        <p>
          <button onClick={this.handleApply.bind(this)}>Сменить пароль</button>
        </p>
      </div>
    )
  }
  
  handleOwnPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      ownPassword: e.currentTarget.value
    })
  }
  
  handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      password: e.currentTarget.value
    })
  }
  
  handleApply() {
    let password = this.state.password.trim()
    if (password.length < 3) {
      toast.error('Password should be at least 3 characters long')
      return
    }
    
    api.accountResetPassword(this.props.accountId, {
      password: password,
      ownPassword: this.state.ownPassword,
    }).then(
      (result) => {
        toast.success('Password updated')
        this.props.history.push(`/user/${this.props.accountId}`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  reloadAccount() {
    api.accountGet(this.props.accountId).then(
      (result) => {
        this.setState({
          account: result,
          isLoaded: true,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default withRouter(AccountPassword)
