import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import EntitiesList from '../prototypes/list'

import { Account, AccountKindUtil, Branch } from '../../lib/entities'
import api from '../../lib/api'

class AccountsList<TProps> extends EntitiesList<TProps> {
  componentDidMount() {
    super.componentDidMount()
    
    this.reloadBranches()
  }
  
  linkPathRoot(): string {
    return '.'
  }
  
  renderHeader() {
    return (
      <tr>
        <th>ID</th>
        <th>Логин</th>
        <th>Тип</th>
        <th>Бренч</th>
        <th>Регистрация</th>
      </tr>
    )
  }
  
  renderLine(account: Account) {
    let branch: Branch | undefined = (this.state.misc.branchesDic ?? {})[account.branchId]
    return (
      <tr key={`account_${account.id}`}>
        <td><Link to={`${this.linkPathRoot()}/user/${account.id}`}>{account.id.split('-')[0]}</Link></td>
        <td>{account.login}</td>
        <td>{AccountKindUtil.toString(account.kind)}</td>
        <td><Link to={`/branch/${account.branchId}`}>{branch?.title}</Link></td>
        <td>{account.isRegistered ? 'registered' : '-'}</td>
      </tr>
    )
  }
  
  callApiList(page: number) {
    return api.accountsList(page)
  }
  
  callApiCount() {
    return api.accountsCount()
  }
  
  
  reloadBranches() {
    api.branchesList(1).then(
      (result) => {
        let branchesDic: object = result.reduce((a: object, x: Branch) => ({...a, [x.id]: x}), {})
        this.setState((state) => { 
          return {
            ...state,
            misc: {
              ...state.misc,
              branchesDic: branchesDic,
            }
          }
        })
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default AccountsList
