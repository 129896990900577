import React from 'react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import QrCode from '../components/device-info/qr-code'
import DeviceControlRoot from '../components/device-info/control-root'
import RemoveDevice from '../components/device-info/remove'
import DeviceOnMap from '../components/device-info/on-map'
import DeviceRides from '../components/device-info/rides'
import DeviceInfo from '../components/device-info/info'

import { AccountKind, AccountKindUtil } from '../lib/entities'

interface IProps extends RouteComponentProps {
  deviceId: string
  accountKind: AccountKind
}
interface IState { }

class DeviceInfoScreen extends React.Component<IProps, IState> {
  render() {
    let url = this.props.match.url
    return (
      <div className='section'>
        <h3>Самокат {this.props.deviceId}</h3>
        <div className='menu'>
          <div className='menu-panel'>
            <NavLink activeClassName='is-active' to={`${url}`} exact={true}>Управление</NavLink>
            { 
              AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.admin)
                ? <NavLink activeClassName='is-active' to={`${url}/info`}>Инфо</NavLink> 
                : ''
            }
            <NavLink activeClassName='is-active' to={`${url}/map`}>На карте</NavLink>
            {
              AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.admin) 
                ? <NavLink activeClassName='is-active' to={`${url}/rides`}>Поездки</NavLink> 
                : ''
            }
            <NavLink activeClassName='is-active' to={`${url}/qr`}>QR-Код</NavLink>
            {
              AccountKindUtil.isKindLevelAllows(this.props.accountKind, AccountKind.superadmin)
                ? <NavLink activeClassName='is-active-warning' to={`${url}/rm`}>Удалить</NavLink> 
                : ''
            }
          </div>
        </div>
        <div className='content'>
          <Switch>
            <Route path={`${url}/qr`}><QrCode deviceId={this.props.deviceId} /></Route>
            <Route path={`${url}/rm`}><RemoveDevice deviceId={this.props.deviceId} /></Route>
            <Route path={`${url}/map`}><DeviceOnMap deviceId={this.props.deviceId} /></Route>
            <Route path={`${url}/rides`}><DeviceRides deviceId={this.props.deviceId} /></Route>
            <Route path={`${url}/info`}><DeviceInfo deviceId={this.props.deviceId} accountKind={this.props.accountKind} /></Route>
            <Route path={`${url}`}><DeviceControlRoot deviceId={this.props.deviceId} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
}  

export default withRouter(DeviceInfoScreen)
