import React from 'react'
import { withRouter, Redirect, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'

import RootScreen from './root/root.screen'

import state from '../lib/state'
import api from '../lib/api'
import { Account } from '../lib/entities'
import { validTimeRemainingFor } from '../lib/utils/jwt'
import config from '../lib/config'

interface IProps extends RouteComponentProps { }
interface IState { 
  isLoaded: boolean
  account?: Account
}

class LandingScreen extends React.Component<IProps, IState> {
  refreshCheckTimer?: NodeJS.Timeout
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      account: undefined,
    }
  }
  
  componentDidMount() {
    if (this.refreshCheckTimer) {
      clearInterval(this.refreshCheckTimer)
    }
    this.refreshCheckTimer = setInterval(() => this.checkRefreshToken(), 60*1000)//once a minute
    
    this.reloadAppState()
  }
  
  componentWillUnmount() {
    if (this.refreshCheckTimer) {
      clearInterval(this.refreshCheckTimer)
      this.refreshCheckTimer = undefined
    }
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<div>Loading...</div>)
    }
    
    if (!this.state.account) {
      return (<Redirect to='/auth/' />)
    }
    
    return (
      <div>
        <Switch>
          <Route path='/*'>
          {
            this.state.account.isRegistered
              ? <RootScreen account={this.state.account} />
              : <Redirect to='account/' />
          }
          </Route>
        </Switch>
      </div>
    )
  }
  
  reloadAppState() {
    let refreshTimeRemaining = validTimeRemainingFor(state.refreshToken)
    if (refreshTimeRemaining < 0) {
      this.setState({
        isLoaded: true,
        account: undefined
      })
      return
    }
    
    let authTimeRemaining = validTimeRemainingFor(state.authToken)
    if (authTimeRemaining < config.authTokenRefreshTimeBefore) {
      api.refreshToken = state.refreshToken
      this.refreshAuth(() => this.reloadUser())
      return
    }
    
    api.refreshToken = state.refreshToken
    api.authToken = state.authToken
    this.reloadUser()
  }
  
  refreshAuth(onSuccess: () => void) {
    api.authRefresh().then(
      (result) => {
        let authToken = result.authToken
        api.authToken = authToken
        state.authToken = authToken
        state.save()
        
        onSuccess()
      },
      (error) => {
        if (error.status === 401) {
          api.authToken = undefined
          api.refreshToken = undefined
          state.authToken = undefined
          state.refreshToken = undefined
          state.save()
        } else {
          toast.error(error.message)
        }
        
        this.setState({
          isLoaded: true,
          account: undefined,
        })
      }
    )
  }
  
  reloadUser() {
    this.setState({
      isLoaded: false
    })
    
    api.accountGetMy().then(
      (result) => {
        this.setState({
          isLoaded: true,
          account: result,
        })
      },
      (error) => {
        if (error.status === 401) {
          api.authToken = undefined
          state.authToken = undefined
          state.save()
          
          this.reloadAppState()
        } else {
          toast.error(error.message)
        }
      }
    )
  }
  
  checkRefreshToken() {
    let authTimeRemaining = validTimeRemainingFor(state.authToken)
    if (authTimeRemaining < config.authTokenRefreshTimeBefore) {
      this.refreshAuth(() => {})
    }
  }
  
  handlePasswordChange() {
    this.reloadUser()
  }
}

export default withRouter(LandingScreen)
