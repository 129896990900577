import React from 'react'
import QRCodeImage from 'qrcode.react'

import config from '../../lib/config'

interface IProps {
  deviceId: string
}

interface IState { }

class QrCode extends React.Component<IProps, IState> {
  render() {
    let codeLink = config.qrCodeLink.replaceAll('%deviceId%', this.props.deviceId)
    return (
      <div className='qr-code'>
        <QRCodeImage value={codeLink} size={256} />
      </div>
    )
  }
}

export default QrCode
