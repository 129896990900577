import React from 'react'
import { YMaps, Map, Polygon, ZoomControl, GeolocationControl } from 'react-yandex-maps'
import { toast } from 'react-toastify'

import { Zone } from '../../lib/entities'
import api from '../../lib/api'
import config from '../../lib/config'

interface IProps {
  zoneId: number
}

interface IState {
  isLoaded: boolean
  error: Error | null
  zone: Zone | undefined
  isEditing: boolean
  points: number[][]
  title: string | undefined
}

class ZoneMap extends React.Component<IProps, IState> {
  ymapsRef?: any
  mapRef?: any
  polygonRef: any = {}
  updatedPoints: number[][] = []
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      error: null,
      zone: undefined,
      isEditing: false,
      points: [],
      title: undefined,
    }
  }
  
  componentDidMount() {
    this.reloadData()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<div>Loading...</div>)
    }
    
    if (this.state.error) {
      return (<div>Error: {this.state.error.message}</div>)
    }
    
    return (
      <div className='device-map'>
        {this.renderControls()}
        <YMaps query={{ apikey: config.ymapsKey, load: 'util.bounds,util.requireCenterAndZoom' }}>
          <Map 
            instanceRef={this.onMapRef.bind(this)}
            onLoad={this.onYmapsRef.bind(this)}
            defaultState={{ center: [55.753215, 37.622504], zoom: 10 }} 
            width='100%' 
            height='500px' 
            modules={['geoObject.addon.editor', 'geolocation']}
          >
            <ZoomControl />
            <GeolocationControl />
            <Polygon
              instanceRef={this.onPolyRef.bind(this)}
              defaultGeometry={[this.state.points]}
              options={{
                fillColor: "#00FF0044",
                strokeColor: "#0000FF88",
                strokeWidth: 5,
                
                editorDrawingCursor: "crosshair",
                editorMaxPoints: 12,
              }}
              
            />
          </Map>
        </YMaps>
      </div>
    )
  }
  
  renderControls() {
    if (!this.state.isEditing) {
      return (
        <div>
          <p>{this.state.title ?? this.state.zone?.title ?? ''}</p>
          <p><button onClick={this.handleEdit.bind(this)}>Редактировать</button></p>
        </div>
      )
    }
    
    return (
      <div>
        <p>
          <input value={this.state.title ?? this.state.zone?.title ?? ''} onChange={this.handleTitleChange.bind(this)} />
        </p>
        <p>
          <button onClick={this.handleSave.bind(this)}>Сохранить</button>
          &nbsp;
          <button onClick={this.handleCancel.bind(this)}>Отменить</button>
        </p>
      </div>
    )
  }
  
  reloadData() {
    api.zoneGet(this.props.zoneId).then(
      (result) => {
        let zone: Zone = result
        let points = JSON.parse(zone.pointsJsonString) ?? []
        this.setState({
          isLoaded: true,
          zone: zone,
          points: points,
        })
        this.updateMapCenter(zone)
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  onYmapsRef(ref: any) {
    if (!ref) { return }
    this.ymapsRef = ref
    this.updateMapCenter(this.state.zone)
  }
  
  onMapRef(ref: any) {
    if (!ref) { return }
    this.mapRef = ref
    this.updateMapCenter(this.state.zone)
  }
  
  onPolyRef(ref: any) {
    if (!ref) { return }
    this.polygonRef = ref
    ref.geometry.events.add('change', (e: any) => {
      let points = [...e.get('newCoordinates')[0]]
      points.pop()
      this.updatedPoints = points
    })
  }
  
  handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      title: e.target.value,
    })
  }
  
  handleEdit() {
    this.updatedPoints = this.state.points
    
    if (this.updatedPoints.length > 2) {
      this.polygonRef.editor.startEditing()
    } else {
      this.polygonRef.editor.startDrawing()
    }
    
    
    this.setState({
      isEditing: true
    })
  }
  
  handleCancel() {
    //TODO: (1) don't reload here
    window.location.reload()
  }
  
  handleSave() {
    let points = this.updatedPoints
    if (points.length < 3) {
      toast.error('Нужно хотя бы 3 точки')
      return
    }
    
    api.zoneUpdate(this.props.zoneId, this.state.title, JSON.stringify(points)).then(
      (_result) => {
        //TODO: (1) don't reload here
        window.location.reload()
      }, 
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  updateMapCenter(zone?: Zone) {
    if (!zone || !this.ymapsRef || !this.mapRef) { return }
    
    let map = this.mapRef
    let points = JSON.parse(zone.pointsJsonString) ?? []
    if (points.count < 2) { return }
    
    let bounds: number[][] = this.ymapsRef.util.bounds.fromPoints(points)
    this.ymapsRef.util.requireCenterAndZoom(
      map.getType(),
      bounds,
      map.container.getSize()
    ).then(function (result: any) {
      map.setCenter(result.center, result.zoom);
    });
  }
}

export default ZoneMap
