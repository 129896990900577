import React from 'react'
import { toast } from 'react-toastify'

import { PriceRate } from '../../lib/entities'

import api from '../../lib/api'

interface IProps {
  branchId: number
}

interface IState { 
  priceRates: PriceRate[]
  isLoaded: boolean
  error: Error | undefined
  isEditing: boolean
}

class BranchPrices extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      priceRates: [],
      isLoaded: false,
      error: undefined,
      isEditing: false,
    }
  }
  
  componentDidMount() {
    this.reloadPriceRates()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return <div><p>Loading...</p></div>
    }
    
    if (this.state.error) {
      return <div><p>Error: {this.state.error.message}</p></div>
    }
    
    return (
      <div>
        <h4>Прайс-лист</h4>
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>Цена</th>
                <th>Время</th>
                <th>Минимальный<br />заряд батареи</th>
              </tr>
            </thead>
            <tbody>
              {this.renderPriceRates()}
            </tbody>
          </table>
        </div>
        {this.renderControls()}
      </div>
    )
  }
  
  renderPriceRates() {
    if (!this.state.isEditing) {
      return this.state.priceRates.map((priceRate) => {
        return (
          <tr key={`rate_${priceRate.id}`}>
            <td>{priceRate.price}р</td>
            <td>{priceRate.minutes} мин</td>
            <td>{priceRate.minBattery}%</td>
          </tr>
        )
      })
    }
    
    return this.state.priceRates.map((priceRate) => {
      return (
        <tr key={`rate_edit_${priceRate.id}`}>
          <td><input value={priceRate.price} id={`rate_price_${priceRate.id}`} onChange={this.handleRateChange.bind(this)} />&nbsp;р</td>
          <td><input value={priceRate.minutes} id={`rate_minutes_${priceRate.id}`} onChange={this.handleRateChange.bind(this)} />&nbsp;мин</td>
          <td><input value={priceRate.minBattery} id={`rate_battery_${priceRate.id}`} onChange={this.handleRateChange.bind(this)} />&nbsp;%</td>
        </tr>
      )
    })
  }
  
  renderControls() {
    if (this.state.isEditing) {
      return (
        <div>
          <p>
            <button onClick={this.handleAddAnother.bind(this)}>Добавить еще</button>
          </p>
          <p>
            <button onClick={this.handleApply.bind(this)}>Сохранить</button>
            &nbsp;
            <button onClick={this.handleCancel.bind(this)}>Отменить</button>
          </p>
        </div>
      )
    }
    
    return (
      <p>
        <button onClick={this.handleBeginEdit.bind(this)}>Редактировать</button>
      </p>
    )
  }
  
  
  reloadPriceRates() {
    this.setState({
      isLoaded: false,
      error: undefined,
    })
    
    api.pricesGet(this.props.branchId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          priceRates: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  
  handleBeginEdit() {
    this.setState({
      isEditing: true 
    })
  }
  
  handleCancel() {
    this.setState({
      isEditing: false
    })
    
    this.reloadPriceRates()
  }
  
  handleAddAnother() {
    this.setState((state) => {
      let priceRates = state.priceRates
      
      let nextId: number = -1
      while (priceRates.find((rate) => rate.id === nextId)) {
        nextId -= 1
      }
      
      let priceRate: PriceRate = {
        id: nextId,
        branchId: this.props.branchId,
        price: 100,
        minutes: 10,
        minBattery: 0,
      }
      
      return {
        priceRates: [ ...priceRates, priceRate ]
      }
    })
  }
  
  handleRateChange(e: React.ChangeEvent<HTMLInputElement>) {
    let parts = e.currentTarget.id.split('_')
    let value = +e.currentTarget.value
    
    this.setState((state) => {
      let priceRates = state.priceRates
      let index = priceRates.findIndex((priceRate) => priceRate.id === +parts[2])
      if (index === -1) { 
        return {
          priceRates: priceRates
        }
      }
      
      switch (parts[1]) {
        case 'price':
          priceRates[index] = { ...priceRates[index], price: value }
          break
        
        case 'minutes': 
          priceRates[index] = { ...priceRates[index], minutes: value }
          break
        
        case 'battery': 
          priceRates[index] = { ...priceRates[index], minBattery: value }
          break
      }
      
      return {
        priceRates: priceRates
      }
    })
  }
  
  handleApply() {
    api.pricesPost(this.props.branchId, this.state.priceRates).then(
      (_result) => {
        toast.done('Цены сохранены')
        this.setState({
          isEditing: false
        })
        this.reloadPriceRates()
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default BranchPrices
