import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Branch } from '../../lib/entities'
import config from '../../lib/config'
import api from '../../lib/api'

interface IProps extends RouteComponentProps { }

interface IState { 
  deviceId: string
  branchId: number
  branches: Branch[]
}

class DeviceAdd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      deviceId: '',
      branchId: 1,
      branches: [],
    }
  }
  
  componentDidMount() {
    this.reloadBranches()
  }
  
  linkPathRoot(): string {
    return '..'
  }
  
  render() {
    return (
      <div>
        <h4>Создать новый самокат</h4>
        <p>
          Номер самоката
          &nbsp;
          <input type='text' value={this.state.deviceId} onChange={this.handleDeviceIdChange.bind(this)} />
        </p>
        <p>
          Бренч
          &nbsp;
          { this.renderBranchSelector() }
        </p>
        <p>
          <button onClick={this.handleAdd.bind(this)}>Создать новый самокат</button>
        </p>
      </div>
    )
  }
  
  renderBranchSelector() {
    return (
      <select value={this.state.branchId} onChange={this.handleBranchIdChange.bind(this)}>
        { this.state.branches.map((branch) => this.renderBranchOption(branch) ) }
      </select>
    )
  }
  
  renderBranchOption(branch: Branch) {
    return <option value={branch.id} key={`branch_${branch.id}`}>{branch.title}</option>
  }
  
  reloadBranches() {
    api.branchesList(1).then(
      (result) => {
        this.setState({
          branches: result
        })
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  handleDeviceIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      deviceId: e.currentTarget.value
    })
  }
  
  handleBranchIdChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      branchId: +e.currentTarget.value
    })
  }
  
  handleAdd() {
    let deviceId = this.state.deviceId.trim()
    if (deviceId.length < config.deviceIdMinLength) {
      toast.error(`Номер самоката должен быть хотя бы ${config.deviceIdMinLength} символов`)
      return
    }
    
    api.devicesAdd(deviceId, this.state.branchId).then(
      (result) => {
        toast.success('Самокат создан')
        this.props.history.push(`${this.linkPathRoot()}/device/${deviceId}`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(DeviceAdd)
