import { Device, DeviceInfo, RiderInfo, DeviceLocation, Zone } from '../../../lib/entities'
import { DevicePoint, DeviceMode } from '../../../lib/types'
import { getTime } from '../../../lib/helpers'
import api from '../../../lib/api'
import config from '../../../lib/config'

type MHLoadingUpdateBlock = (state: {isLoading: boolean, error?: Error}) => void
type MHPointsUpdateBlock = (result: {points: DevicePoint[], updateTime: number, devicesTotal: number, devicesInUse: number}) => void
type MHZonesUpdateBlock = (zones: Zone[]) => void 
type MHCallbacks = { onLoadingUpdate: MHLoadingUpdateBlock, onPointsUpdate: MHPointsUpdateBlock, onZonesUpdate: MHZonesUpdateBlock }

export enum MapFilter {
  all, live, service, lowBattery, offline
}

export default class MapHelper
{
  private callbacks: MHCallbacks
  
  private branchId: number
  private devices: Device[] = []
  private deviceInfos: {[key: string]: DeviceInfo} = {}
  private riderInfos: {[key: string]: RiderInfo} = {}
  private deviceLocations: {[key: string]: DeviceLocation} = {}
  private activeFilter: MapFilter
  private lastReloadTime: number = getTime()
  
  constructor(branchId: number, callbacks: MHCallbacks, filter: MapFilter = MapFilter.all) {
    this.branchId = branchId
    this.callbacks = callbacks
    this.activeFilter = filter
  }
  
  public reloadDevices() {
    this.loadDevices()
  }
  
  public use(filter: MapFilter) {
    this.activeFilter = filter
    this.updatePoints()
  }
  
  private loadDevices(page: number = 1) {
    this.callbacks.onLoadingUpdate({ isLoading: true })
    
    api.devicesList(page, { hideGaraged: true, branchId: this.branchId }).then(
      (result) => {
        let devices: Device[] = result
        
        if (page === 1) {
          this.devices = devices
          this.reloadZones()
        } else {
          this.devices = this.devices.concat(devices)
        }
        
        if (devices.length < 1) { //last page
          this.reloadDeviceInfos()
          return
        }
        
        this.loadDevices(page + 1)
      },
      (error) => {
        this.callbacks.onLoadingUpdate({ isLoading: false, error })
      }
    )
  }
  
  private reloadDeviceInfos() {
    let deviceIds: string[] = this.devices.map(device => device.id)
    if (deviceIds.length < 1) {
      this.deviceInfos = {}
      this.reloadDeviceLocations()
      return
    }
    
    api.devicesListInfo(deviceIds).then(
      (result) => {
        let deviceInfos: DeviceInfo[] = result.deviceInfos
        let deviceInfosDic: {[key: string]: DeviceInfo} = {}
        deviceInfos.forEach((info) => {
          deviceInfosDic[info.deviceId] = info
        })
        
        let riderInfos: RiderInfo[] = result.riderInfos
        let riderInfosDic: {[key: string]: RiderInfo} = {}
        riderInfos.forEach((info) => {
          riderInfosDic[info.deviceId] = info
        })
        
        this.deviceInfos = deviceInfosDic
        this.riderInfos = riderInfosDic
        this.lastReloadTime = getTime()
        
        this.reloadDeviceLocations()
      },
      (error) => {
        this.callbacks.onLoadingUpdate({ isLoading: false, error })
      }
    )
  }
  
  private reloadDeviceLocations() {
    let deviceIds: string[] = this.devices.map(device => device.id)
    if (deviceIds.length < 1) {
      this.deviceLocations = {}
      this.updatePoints()
      
      this.callbacks.onLoadingUpdate({ isLoading: false })
      return
    }
    
    api.devicesListLocation(deviceIds).then(
      (result) => {
        let locations: DeviceLocation[] = result
        let deviceLocations: {[key: string]: DeviceLocation} = {}
        locations.forEach((location) => {
          deviceLocations[location.deviceId] = location
        })
        
        this.deviceLocations = deviceLocations
        this.updatePoints()
        
        this.callbacks.onLoadingUpdate({ isLoading: false })
      },
      (error) => {
        this.callbacks.onLoadingUpdate({ isLoading: false, error })
      }
    )
  }
  
  private reloadZones() {
    api.zonesList(1, this.branchId).then(
      (result) => {
        let zones: Zone[] = result ?? []
        this.callbacks.onZonesUpdate(zones)
      },
      (error) => { 
        console.log('Error loading zones: ', error)
      }
    )
  }
  
  private updatePoints() {
    let pointsRaw: (DevicePoint | null)[] = this.devices.map((device) => {
      let deviceInfo = this.deviceInfos[device.id]
      let deviceLocation = this.deviceLocations[device.id]
      
      if (!deviceInfo || !deviceLocation) {
        return null
      }
      
      return {
        device: device,
        info: deviceInfo,
        rider: this.riderInfos[device.id],
        location: deviceLocation,
      }
    })
    
    let points = pointsRaw.filter(point => {
      if (!point) { return null }
      
      switch (this.activeFilter) {
        case MapFilter.live: return point.device.mode === DeviceMode.live
        case MapFilter.service: return point.device.mode === DeviceMode.service
        case MapFilter.lowBattery: return point.info.battery < config.deviceBatteryWarning
        case MapFilter.offline: return point.info.time < this.lastReloadTime - config.deviceOfflineTimeWarning
      }
      
      return true
    }) as DevicePoint[]
    
    let now = getTime()
    let devicesTotal: number = points.length
    let devicesInUse: number = points.reduce((result, point) => {
      if (!point || point.device.mode !== DeviceMode.live) { return result }
      
      return result + ((point.rider?.rideExpTime ?? 0) > now ? 1 : 0)
    }, 0)
    
    this.callbacks.onPointsUpdate({ points, updateTime: this.lastReloadTime, devicesTotal, devicesInUse })
  }
  
  
  static filterTitle(filter: MapFilter): string {
    switch (filter) {
      case MapFilter.all: return 'Все'
      case MapFilter.lowBattery: return 'Разряженные'
      case MapFilter.offline: return 'Оффлайн'
      case MapFilter.live: return 'В поле'
      case MapFilter.service: return 'В сервисе'
      default: return ''
    }
  }
  
  static availableFilters(): MapFilter[] {
    return [ MapFilter.all, MapFilter.live, MapFilter.service, MapFilter.lowBattery, MapFilter.offline ]
  }
}
