import React from 'react'
import { YMaps, Map, Placemark, ZoomControl, Polygon, GeolocationControl } from 'react-yandex-maps'
import { Link } from 'react-router-dom'

import MapHelper, { MapFilter } from './helpers/map-helper'
import { Zone, Branch } from '../../lib/entities'
import { DevicePoint, DeviceMode } from '../../lib/types'
import { dateString, dateForTime, getTime } from '../../lib/helpers'
import config from '../../lib/config'

interface IProps { 
  branch: Branch
}

interface IState {
  points: DevicePoint[]
  zones: Zone[]
  isLoaded: boolean
  error?: Error
  devicesTotal: number
  devicesInUse: number
  centerZone?: Zone
  updateTime: number
  activeFilter?: MapFilter
}

class DevicesMap extends React.Component<IProps, IState> {
  mapHelper: MapHelper
  ymapsRef?: any
  mapRef?: any
  
  constructor(props: IProps) {
    super(props)
    
    let filter = MapFilter.live
    
    this.state = {
      points: [],
      zones: [],
      isLoaded: true,
      updateTime: getTime(),
      devicesTotal: 0,
      devicesInUse: 0,
      activeFilter: filter,
    }
    
    this.mapHelper = new MapHelper(props.branch.id, {
      onLoadingUpdate: (state) => { 
        this.setState({
          isLoaded: !state.isLoading,
          error: state.error,
        })
      },
      onPointsUpdate: (update) => {
        this.setState({
          points: update.points,
          updateTime: update.updateTime,
          devicesTotal: update.devicesTotal,
          devicesInUse: update.devicesInUse,
        })
      },
      onZonesUpdate: (zones) => {
        let centerZone = zones[0]
        this.setState({ zones, centerZone })
        this.updateMapCenter(centerZone)
      },
    }, filter)
  }
  
  componentDidMount() {
    this.mapHelper.reloadDevices()
  }
  
  linkPathRoot(): string {
    return '.'
  }
  
  render() {
    return (
      <div>
        <h4>
          Карта самокатов{this.state.isLoaded ? '' : ' грузится..'}&nbsp;
          (катается {this.state.devicesInUse} из {this.state.devicesTotal})
        </h4>
        {
          this.state.error 
            ? <p>Error: {this.state.error.message}</p>
            : ''
        }
        {this.renderZoneSelector()}
        {this.renderFilterSelector()}
        <div className='device-map'>
          <YMaps query={{ apikey: config.ymapsKey, load: 'util.bounds,util.requireCenterAndZoom' }}>
            <Map 
              defaultState={{ center: [55.753215, 37.622504], zoom: 10 }}
              width='100%' height='500px'
              modules={['geoObject.addon.balloon', 'geolocation']}
              instanceRef={this.onMapRef.bind(this)}
              onLoad={this.onYmapsRef.bind(this)}
            >
              <ZoomControl />
              <GeolocationControl />
              {this.state.zones.map(zone => this.renderZone(zone))}
              {this.state.points.map(point => this.renderPoint(point))}
            </Map>
          </YMaps>
        </div>
      </div>
    )
  }
  
  renderZoneSelector() {
    if (this.state.zones.length < 2) {
      return ""
    }
    
    return (
      <div className='menu white'>
        <div className='menu-panel'>
          {this.state.zones.map(zone => {
            return (
              <Link 
                className={this.state.centerZone?.id === zone.id ? 'is-active' : ''} 
                to='#' 
                onClick={this.onZoneSelect.bind(this)}
                id={`zonebtn_${zone.id}`}
                key={`zonebtn_${zone.id}`}
              >
                {zone.title}
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
  
  renderFilterSelector() {
    return (
      <div className='menu white'>
        <div className='menu-panel'>
          {MapHelper.availableFilters().map(filter => {
            return (
              <Link to='#'
                className={this.state.activeFilter === filter ? 'is-active' : ''} 
                onClick={this.onFilterSelect.bind(this)}
                id={`filterbtn_${filter}`}
                key={`filterbtn_${filter}`}
              >
                {MapHelper.filterTitle(filter)}
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
  
  renderPoint(point: DevicePoint) {
    let now = getTime()
    let isInUse = (point.device?.mode === DeviceMode.live) && (point.rider?.rideExpTime ?? 0) > now
    return <Placemark 
      key={`placemark_${point.device.id}`}
      geometry={[point.location.lat, point.location.lng]}
      defaultProperties={{
        iconContent: isInUse ? `*${point.device.id}*` : point.device.id,
        balloonContentHeader: this.balloonContentHeaderFor(point, isInUse),
        balloonContent: this.balloonContentFor(point),
      }}
      defaultOptions={{
        preset: `islands#${this.colorFor(point)}StretchyIcon`,
      }}
    />
  }
  
  renderZone(zone: Zone) {
    let poly = JSON.parse(zone.pointsJsonString)
    return <Polygon
      key={`zone_${zone.id}`}
      geometry={[poly]}
      options={{ fillColor: "#00FF0000", strokeColor: "#0000FF88", strokeWidth: 5 }}
    />
  }
  
  colorFor(point: DevicePoint): string {
    if (point.info.battery < config.deviceBatteryError) return 'red'
    if (point.info.time < this.state.updateTime - config.deviceOfflineTimeError) return 'red'
    
    if (point.info.battery < config.deviceBatteryWarning) return 'yellow'
    if (point.info.time < this.state.updateTime - config.deviceOfflineTimeWarning) return 'yellow'
    
    if (point.info.battery < config.deviceBatteryPreWarning) return 'green'
    
    return 'lightBlue'
  }
  
  balloonContentHeaderFor(point: DevicePoint, isInUse: boolean): string {
    let text: string = isInUse ? `${point.device.id} катается` : point.device.id
    return `<a href="${this.linkPathRoot()}/device/${point.device.id}">${text}</a>`
  }
  
  balloonContentFor(point: DevicePoint): string {
    return `Батарея ${point.info.battery}%`
      + '<br />Состояние от ' + dateString(dateForTime(point.info.time))
      + '<br />Координаты от ' + dateString(dateForTime(point.location.time))
  }
  
  onZoneSelect(e: React.PointerEvent<HTMLAnchorElement>) {
    let zoneId = e.currentTarget.id.split('_')[1]
    let centerZone = this.state.zones.find(zone => +zone.id === +zoneId)
    this.setState({ centerZone })
    
    this.updateMapCenter(centerZone)
  }
  
  onFilterSelect(e: React.PointerEvent<HTMLAnchorElement>) {
    let activeFilter: MapFilter | undefined = +e.currentTarget.id.split('_')[1]
    this.setState({ activeFilter })
    
    this.mapHelper.use(activeFilter)
  }
  
  onYmapsRef(ref: any) {
    if (!ref) { return }
    this.ymapsRef = ref
    this.updateMapCenter(this.state.centerZone)
  }
  
  onMapRef(ref: any) {
    if (!ref) { return }
    this.mapRef = ref
    this.updateMapCenter(this.state.centerZone)
  }
  
  updateMapCenter(zone?: Zone) {
    if (!zone || !this.ymapsRef || !this.mapRef) { return }
    
    let map = this.mapRef
    let points = JSON.parse(zone.pointsJsonString) ?? []
    if (points.count < 2) { return }
    
    let bounds: number[][] = this.ymapsRef.util.bounds.fromPoints(points)
    this.ymapsRef.util.requireCenterAndZoom(
      map.getType(),
      bounds,
      map.container.getSize()
    ).then(function (result: any) {
      map.setCenter(result.center, result.zoom);
    });
  }
}

export default DevicesMap
