import { Link } from 'react-router-dom'

import EntitiesList from '../prototypes/list'

import { Branch } from '../../lib/entities'
import api from '../../lib/api'

class BranchesList<TProps> extends EntitiesList<TProps> {
  renderHeader() {
    return (
      <tr>
        <th>Бренчи</th>
      </tr>
    )
  }
  
  renderLine(branch: Branch) {
    return (
      <tr key={`branch_${branch.id}`}>
        <td><Link to={`/branch/${branch.id}`}>{this.titleFor(branch)}</Link></td>
      </tr>
    )
  }
  
  titleFor(branch: Branch) {
    if (!branch.title || branch.title.length < 1) {
      return `Бренч ${branch.id}`
    }
    
    return branch.title
  }
  
  callApiList(page: number) {
    return api.branchesList(page)
  }
  
  callApiCount() {
    return api.branchesCount()
  }
}

export default BranchesList
