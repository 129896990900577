import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import api from '../../lib/api'

interface IProps extends RouteComponentProps { 
  branchId: number
}

interface IState { 
  title: string
}

class ZoneAdd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      title: ''
    }
  }
  
  linkPathRoot(): string {
    return '..'
  }
  
  render() {
    return (
      <div>
        <h4>Название новой зоны</h4>
        <p>
          <input type='text' value={this.state.title} onChange={this.handleTitleChange.bind(this)} />
        </p>
        <p>
          <button onClick={this.handleAdd.bind(this)}>Создать новую зону</button>
        </p>
      </div>
    )
  }
  
  handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      title: e.currentTarget.value
    })
  }
  
  handleAdd() {
    let title = this.state.title.trim()
    if (title.length < 3) {
      toast.error(`Название должно быть хотя бы из 3х символов`)
      return
    }
    
    api.zonesAdd(title, this.props.branchId).then(
      (result) => {
        toast.success('Зона создана')
        this.props.history.push(`${this.linkPathRoot()}/zone/${result.id}`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(ZoneAdd)
