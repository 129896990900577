import React from 'react'

import BranchPrices from '../../components/branch-info/prices'

import { Branch } from '../../lib/entities'

interface IProps {
  branch: Branch
}

class PricesScreen extends React.Component<IProps> {
  render() {
    return (
      <div className='section'>
        <div className='content'>
          <BranchPrices branchId={this.props.branch.id} />
        </div>
      </div>
    )
  }
}

export default PricesScreen
