import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import PassGen from 'generate-password'

import { AccountKind, AccountKindUtil, Branch, Account } from '../../lib/entities'

import api from '../../lib/api'

interface IProps extends RouteComponentProps { 
  account: Account
  branchId?: number
}

interface IState {
  branches: Branch[] 
  login: string
  accountKind: AccountKind
  branchId: number
  password: string
}

class AccountAdd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    let password: string = PassGen.generate({ numbers: true, length: 10 })
    this.state = {
      branches: [],
      login: '',
      accountKind: AccountKind.serviceman,
      branchId: this.props.branchId ?? 1,
      password: password,
    }
  }
  
  componentDidMount() {
    this.reloadBranches()
  }
  
  linkPathRoot(): string {
    return '..'
  }
  
  render() {
    return (
      <div>
        <h4>Создать нового пользователя</h4>
        <p>
          Бренч
          &nbsp;
          { this.renderBranchSelector() }
        </p>
        <p>
          Тип
          &nbsp;
          { this.renderAccountKindSelector() }
        </p>
        <p>
          Логин
          &nbsp;
          <input type='text' value={this.state.login} onChange={this.handleLoginChange.bind(this)} />
        </p>
        <p>
          Пароль
          &nbsp;
          <input type='text' value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
        </p>
        <p>
          <button onClick={this.handleAdd.bind(this)}>Создать нового пользователя</button>
        </p>
      </div>
    )
  }
  
  renderAccountKindSelector() {
    let allowedKinds: AccountKind[] = [ AccountKind.serviceman, AccountKind.admin ]
    if (this.props.account.kind === AccountKind.superadmin) {
      allowedKinds.push(AccountKind.superadmin)
    }
    
    return (
      <select value={this.state.accountKind} onChange={this.handleAccountKindChange.bind(this)}>
        {
          allowedKinds.map(kind => (
            <option value={kind} key={`kind_${kind}`}>{AccountKindUtil.toString(kind)}</option>
          ))
        }
      </select>
    )
  }
  
  renderBranchSelector() {
    if (this.state.accountKind === AccountKind.superadmin) {
      return <b>Все бренчи</b>
    }
    
    return (
      <select value={this.state.branchId} onChange={this.handleBranchChange.bind(this)}>
        {
          this.state.branches.map(branch => (
            <option value={branch.id} key={`branch_${branch.id}`}>{branch.title}</option>
          ))
        }
      </select>
    )
  }
  
  handleLoginChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      login: e.currentTarget.value
    })
  }
  
  handleAccountKindChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      accountKind: e.currentTarget.value as AccountKind
    })
  }
  
  handleBranchChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      branchId: +e.currentTarget.value
    })
  }
  
  handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      password: e.currentTarget.value
    })
  }
  
  handleAdd() {
    let login = this.state.login.trim()
    if (login.length < 3) {
      toast.error('Логин должен быть хотя бы из 3х символов')
      return
    }
    
    let password = this.state.password.trim()
    if (password.length < 3) {
      toast.error('Пароль должен быть хотя бы из 3х символов')
      return
    }
    
    api.accountCreateAdmin({ 
      login: login, 
      password: password, 
      kind: this.state.accountKind, 
      branchId: this.state.accountKind === AccountKind.superadmin ? -1 : this.state.branchId,
    }).then(
      (result) => {
        toast.success('Пользователь создан')
        let accountId = result.id
        this.props.history.push(`${this.linkPathRoot()}/user/${accountId}`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  reloadBranches() {
    api.branchesList(1).then(
      (result) => {
        this.setState({
          branches: result,
        })
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(AccountAdd)
