import React from 'react'
import { toast } from 'react-toastify'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import api from '../../lib/api'

interface IProps extends RouteComponentProps {
  accountId: string
}

interface IState {
  enteredAccountId: string
}

class RemoveAccount extends React.Component<IProps, IState> {
  shortenedAccountId: string = ''
  
  constructor(props: IProps) {
    super(props)
    
    this.shortenedAccountId = props.accountId.split('-')[0]
    
    this.state = {
      enteredAccountId: ''
    }
  }
  
  linkPathRoot(): string {
    return '../..'
  }
  
  render() {
    return (
      <div>
        <h4>Введите {this.shortenedAccountId}, чтобы безвозвратно удалить аккаунт</h4>
        <p>
          <input type='text' value={this.state.enteredAccountId} onChange={this.handleAccountIdChange.bind(this)} />
        </p>
        <p>
          <button disabled={this.state.enteredAccountId !== this.shortenedAccountId} onClick={this.handleRemove.bind(this)}>Удалить безвозвратно</button>
        </p>
      </div>
    )
  }
  
  handleAccountIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      enteredAccountId: e.currentTarget.value
    })
  }
  
  handleRemove() {
    api.accountRm(this.props.accountId).then(
      (result) => {
        toast.success('Пользователь удален')
        this.props.history.push(`${this.linkPathRoot()}/users`)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(RemoveAccount)
