import React from 'react'
import { withRouter, Switch, Route, NavLink } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import BranchesList from '../../components/branches/list'
import BranchesAdd from '../../components/branches/add'

import fixUrl from '../../lib/utils/fix-url'

interface IProps extends RouteComponentProps {}
interface IState {}

class BranchesScreen extends React.Component<IProps, IState> {
  render() {
    let path = fixUrl(this.props.match.path)
    let url = fixUrl(this.props.match.url)
    return (
      <div className='section'>
        <Switch>
          <Route path={`${path}/`} exact>
            <div className='menu white'>
              <div className='menu-panel'>
                <NavLink activeClassName='is-active' to={`${url}/new`}>Создать новый</NavLink>
              </div>
            </div>
          </Route>
        </Switch>
        <div className='content'>
          <Switch>
            <Route path={`${path}/new`}><BranchesAdd /></Route>
            <Route path={`${path}/`}><BranchesList /></Route>
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(BranchesScreen)
