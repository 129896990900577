import React from 'react'
import './App.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LandingScreen from './screens/landing.screen'
import AuthScreen from './screens/auth.screen'

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route path="/auth"><AuthScreen /></Route>
                        <Route path="/"><LandingScreen /></Route>
                    </Switch>
                </BrowserRouter>
                <ToastContainer />
            </div>
        )
    }
}

export default App
