import React from 'react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import ZoneMap from '../components/zone-info/map'
import ZoneRemove from '../components/zone-info/remove'

interface IProps extends RouteComponentProps {
  zoneId: number
}
interface IState { }

class ZoneInfoScreen extends React.Component<IProps, IState> {
  render() {
    let url = this.props.match.url
    return (
      <div className='device-info section'>
        <h3>Зона {this.props.zoneId}</h3>
        <div className='menu white'>
          <div className='menu-panel'>
            <NavLink activeClassName='is-active' to={`${url}`} exact={true}>Инфо</NavLink>
            <NavLink activeClassName='is-active-warning' to={`${url}/rm`}>Удалить</NavLink>
          </div>
        </div>
        <div className='content'>
          <Switch>
            <Route path={`${url}/rm`}><ZoneRemove zoneId={this.props.zoneId} /></Route>
            <Route path={`${url}`}><ZoneMap zoneId={this.props.zoneId} /></Route>
          </Switch>
        </div>
      </div>
    )
  }
}  

export default withRouter(ZoneInfoScreen)
