export interface Account {
  id: string
  login: string
  isRegistered: boolean
  createdDate: string
  kind: AccountKind
  branchId: number
}

export enum AccountKind {
  anonymous = 'anonymous',
  registered = 'registered',
  serviceman = 'serviceman',
  admin = 'admin',
  superadmin = 'superadmin',
}

export class AccountKindUtil {
  public static toString(mode: AccountKind): string {
    switch (mode) {
      case AccountKind.serviceman: return "техник"
      case AccountKind.admin: return "админ бренча"
      case AccountKind.superadmin: return "суперадмин"
      case AccountKind.registered: return "пользователь"
      case AccountKind.anonymous: return "аноним"
      default: return "неизвестный"
    }
  }
  
  public static toAccessLevel(kind: AccountKind): number {
    switch (kind) {
      case AccountKind.anonymous: return 0
      case AccountKind.registered: return 1
      case AccountKind.serviceman: return 2
      case AccountKind.admin: return 3
      case AccountKind.superadmin: return 4
      default: return -1
    }
  }
  
  public static isKindLevelAllows(kind: AccountKind, actionsFromKind: AccountKind): boolean {
    return this.toAccessLevel(kind) >= this.toAccessLevel(actionsFromKind)
  }
}
